import React, { useState, useMemo} from "react";
import styled from 'styled-components'
import DataExportTable from "./DataExportTable";

const ClientBillingMasterExport = (props) => {

   // doing something different with the table.  See comments

  const [show, setShow] = useState(false);
  const [valueSelected, setValueSelected] = useState({})
  const [modalType, setModalType] = useState("None")

  console.log("in ClientBillingMasterExport")
  // Depending on path of access, we will be reading data from session storage to populate
  // the table.  So, we need a place to override what's passed in from ModalContainer for
  // one use case / path.  Create data array to store the override

  let data = []

  // preprocessing... if props.selectedValue is not null, it means table was called from a
  // ...CRUD form and user wants to review singleton data for export.  Otherwise, rely on
  // what's been written to sessionStorage.


  if (typeof props.valueSelected !== 'undefined' ) {

    data = props.data.filter(obj => {
      return obj.community_community_id === props.communityId
      })

  } else {

    data = JSON.parse(sessionStorage.getItem("currentData"))
    console.log("-----------data=", data)
  }

    // table styles.  Taken from an example.  Investigate porting to css.  Produces warnings.

    const Wrapper = styled.section`
        padding: 0em;
        padding-top: 0rem;
        padding-bottom: 0rem;
        background: white;
        text-align: left;
        align-items: center;
        alignItems: center;
    `;
    const Styles = styled.div`
        padding: 1rem;
        .rt-thead {
            overflow-y: scroll;
        }
        .table {
          display: inline-block;
          border-spacing: 0;
          border: 1px solid #ccc;;

          .tr {
            :last-child {
              .td {
                ${'' /* border-bottom: 0; */}
                border-bottom: 1px solid #ccc;
              }
            }
          }

          .th {
            margin: 0;
            padding: .5rem;
            border-bottom: 1px solid #ccc;
            border-right: 1px solid #ccc;
            color:  #00447c;
            font-weight: 600;

            :last-child {
              border-right: 1px solid #ccc;
            }
          }


          .td {
            margin: 0;
            padding: .5rem;
            border-bottom: 1px solid #ccc;
            border-right: 1px solid #ccc;
            white-space: nowrap;
            text-overflow:ellipsis;
            overflow: hidden;

            :last-child {
              border-right: 1px solid #ccc;
            }
          }
        }
      `

  // apply fixed format to host ratings

  function toFixedFormat (value) {
    return parseFloat(isNaN(value) ? 0 : value).toFixed(2)
  }

  // simple array to map columns for table.


  const columns = useMemo(
    () => [
      {
        Header: () => (
            <span style={{display: "none"}} ></span>
        ),
        id: 'someId',
        columns: [
          {
            Header: "Billing Contact",
            accessor: "client_billing_name",
            // width: 180,
            Cell: ({row}) => {
                const dataValue = row.original
                const showRatingInformation = () => {
                    setValueSelected(dataValue.game_schedule_id)
                    setModalType("rating information")
                    setShow(true)
                }
            return (
                <div onClick={()=> showRatingInformation()} > {dataValue.client_billing_name} </div>
            )
            }
          },
          {
            Header: "Company",
            accessor: "community_name",
            width: 300,
            Cell: ({row}) => {
                const dataValue = row.original
                const showRatingInformation = () => {
                    setValueSelected(dataValue.game_schedule_id)
                    setModalType("rating information")
                    setShow(true)
                }
            return (
                <div onClick={()=> showRatingInformation()} > {dataValue.community_name} </div>
            )
            }
          },
          {
            Header: "Pref. Payment",
            accessor: "payment_method",
            Cell: ({row}) => {
                const dataValue = row.original
                const showHostInformation = () => {
                    setValueSelected(dataValue.game_schedule_id)
                    setModalType("host contact")
                    setShow(true)
                }
            return (
                <div onClick={()=> showHostInformation()} > {dataValue.payment_method} </div>
            )
            }
          },
          {
            Header: "Inv. Delivery",
            accessor: "delivery_method",
            Cell: ({row}) => {
                const dataValue = row.original
                const showRatingInformation = () => {
                    setValueSelected(dataValue.game_schedule_id)
                    setModalType("rating information")
                    setShow(true)
                }
            return (
                <div onClick={()=> showRatingInformation()} > {dataValue.delivery_method} </div>
            )
            }
          },
          {
            Header: "Contact Email",
            accessor: "email_id",
            Cell: ({row}) => {
                const dataValue = row.original
                const showRatingInformation = () => {
                    setValueSelected(dataValue.game_schedule_id)
                    setModalType("rating information")
                    setShow(true)
                }
            return (
                <div onClick={()=> showRatingInformation()} > {dataValue.email_id} </div>
            )
            }
          },
          {
            Header: "Billing Email",
            accessor: "billing_email",
            Cell: ({row}) => {
                const dataValue = row.original
                const showRatingInformation = () => {
                    setValueSelected(dataValue.game_schedule_id)
                    setModalType("rating information")
                    setShow(true)
                }
            return (
                <div onClick={()=> showRatingInformation()} > {dataValue.billing_email} </div>
            )
            }
          },
          {
            Header: "Office Phone",
            accessor: "office_phone",
            Cell: ({row}) => {
                const dataValue = row.original
                const showRatingInformation = () => {
                    setValueSelected(dataValue.game_schedule_id)
                    setModalType("rating information")
                    setShow(true)
                }
            return (
                <div onClick={()=> showRatingInformation()} > {dataValue.office_phone} </div>
            )
            }
          },
          {
            Header: "Contact Phone",
            accessor: "phone_number",
            Cell: ({row}) => {
                const dataValue = row.original
                const showRatingInformation = () => {
                    setValueSelected(dataValue.game_schedule_id)
                    setModalType("rating information")
                    setShow(true)
                }
            return (
                <div onClick={()=> showRatingInformation()} > {dataValue.phone_number} </div>
            )
            }
          },
          {
            Header: "Website",
            accessor: "web_site",
            Cell: ({row}) => {
                const dataValue = row.original
                const showRatingInformation = () => {
                    setValueSelected(dataValue.game_schedule_id)
                    setModalType("rating information")
                    setShow(true)
                }
            return (
                <div onClick={()=> showRatingInformation()} > {dataValue.web_site} </div>
            )
            }
          },
          {
            Header: "Street",
            accessor: "street_address",
            Cell: ({row}) => {
                const dataValue = row.original
                const showRatingInformation = () => {
                    setValueSelected(dataValue.game_schedule_id)
                    setModalType("rating information")
                    setShow(true)
                }
            return (
                <div onClick={()=> showRatingInformation()} > {dataValue.street_address} </div>
            )
            }
          },
          {
            Header: "City",
            accessor: "city",
            Cell: ({row}) => {
                const dataValue = row.original
                const showRatingInformation = () => {
                    setValueSelected(dataValue.game_schedule_id)
                    setModalType("rating information")
                    setShow(true)
                }
            return (
                <div onClick={()=> showRatingInformation()} > {dataValue.city} </div>
            )
            }
          },
          {
            Header: "State",
            accessor: "state",
            Cell: ({row}) => {
                const dataValue = row.original
                const showRatingInformation = () => {
                    setValueSelected(dataValue.game_schedule_id)
                    setModalType("rating information")
                    setShow(true)
                }
            return (
                <div onClick={()=> showRatingInformation()} > {dataValue.state} </div>
            )
            }
          },
          {
            Header: "Country",
            accessor: "country",
            Cell: ({row}) => {
                const dataValue = row.original
                const showRatingInformation = () => {
                    setValueSelected(dataValue.game_schedule_id)
                    setModalType("rating information")
                    setShow(true)
                }
            return (
                <div onClick={()=> showRatingInformation()} > {dataValue.country} </div>
            )
            }
          },
          {
            Header: "Joined",
            accessor: "date_joined",
            Cell: ({row}) => {
                const dataValue = row.original
                const showRatingInformation = () => {
                    setValueSelected(dataValue.date_joined)
                    setModalType("rating information")
                    setShow(true)
                }
            return (
                <div onClick={()=> showRatingInformation()} > {dataValue.date_joined} </div>
            )
            }
          },
        ]
      },
    ],
    []
  );
  return (
      <Wrapper>
        <Styles>
            <DataExportTable
              columns={columns}
              data={data}
              caller={props.caller}
              setRefresh={props.setRefresh}
              refresh={props.refresh}
              setShow={setShow}
              show={show}
              valueSelected={valueSelected}
              modalType={modalType}
               />
        </Styles>
    </Wrapper>
  );
}

export default ClientBillingMasterExport;