import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Container, Row, Col, Button, Stack, Spinner} from "react-bootstrap"
import SelectNewHostBar from "./SelectNewHostBar";
import MessageModal from "./MessageModal"
import doValidations from "../utils/doValidations";
import ValidationQuery from "../utils/ValidationQuery"
import WriteData from "../utils/WriteData";
import "./Form.css"
import "./HostFormCRUD.css"

const HostFormCRUD = (props) => {

    const REACT_APP_ENDPOINT_HOST=process.env.REACT_APP_ENDPOINT_HOST;
    let apiPath = ''

    console.log("in HostFormCrud")
    const setDataChange = props.setDataChange // destructure for useEffect
    const dataChange = props.dataChange
    console.log("******************HostFormCRUD dataChange= ", dataChange)

    // console.log("props.setRefresh=", props.setRefresh)
    console.log("HostFormCrud formData= ", props.formData)
    // console.log("HostFormCRUD sectionValue = ", props.HostAccountUserId)

    // for use during database updates
    const [dbWorkComplete, setDbWorkComplete] = useState(true);
    const [postDelete, setPostDelete] = useState(false);  //to prevent a preLoadValues load in rerender after record deletion.
     // for use managing internal state when props.CRUD = "Add" to allow for page refreshes and managing
    // inserts versus updates
    const [beenInserted, setBeenInserted] = useState(false)

    // for use with Modal
    const [showInner, setShowInner] = useState(false)
    const handleClose = () => setShowInner(false)
    const [deleteWarning, setDeleteWarning] = useState(false)
    const [saveWarning, setSaveWarning] = useState(false)

    // for use during validation.  There are two types of validations:   lookups against preloaded values and database queries.
    // See doValidations and ValidationQuery(currently used for delete validations only).
    const [titleMessage, setTitleMessage] = useState(null)
    const [validationMessage, setValidationMessage] = useState(null)

    let numberOfRoles = 0
    let gameScheduleCount = 0

    // this is precheck for use in later validation in the event that the user tries to delete a host that has other
    // active roles and/or an active game schedule
    if (props.CRUD === "ReadUpdateDelete") {
        console.log("calling ValidationQuery.....")
        numberOfRoles = ValidationQuery('/check_additional_roles', props.hostUserId, "hosts")
        console.log ("*****numberOfRoles=", numberOfRoles)
        console.log("calling ValidationQuery two.....")
        gameScheduleCount = ValidationQuery('/scheduled_games_host', props.hostUserId, "games")
        console.log ("*****gameScheduleCount=", gameScheduleCount)
    }

    // for use with SelectNewHostBar
    const [hostPicked, setHostPicked] = useState(false)
    const [selectedHostValues, setSelectedHostValues] = useState({})
    const [readComplete, setReadComplete] = useState (false)

    let preloadValues = {}

    // form submit processing
    const onSubmit = async data => {
        console.log(data)
        console.log ("in onSubmit formAction=", getValues("formAction"))
        setDbWorkComplete(false)
        // convert Timezone from array to string for write to database
        //
        if (Array.isArray( data.hostTimezone)) {
            data.hostTimezone = data.hostTimezone.join(', ')
        }
         if (data.availableToPublic) {
             data.availableToPublic = "Y"
         }
         else {
             data.availableToPublic = null
         }
        let passValidation = true
        passValidation = await doValidations("HostFormCRUD", getValues("formAction"), dirtyFields, data, props.formData, setValidationMessage, numberOfRoles, gameScheduleCount)
        if (passValidation) {
            if (getValues("formAction") === "insert") {
                apiPath=REACT_APP_ENDPOINT_HOST + "/host_insert"
                WriteData(apiPath, data, "post", setDbWorkComplete)
                console.log ("insert triggered")
                setBeenInserted(true) // set here to allow updates after insert when user stays on form
            }
            else if (getValues("formAction") === "update") {
                apiPath=REACT_APP_ENDPOINT_HOST + "/host_update"
                WriteData(apiPath, data, "put", setDbWorkComplete, data.userId, props.refresh, props.setRefresh)
                setDbWorkComplete(true)
                console.log ("***** return from WriteData UPDATE")
                // props.handleOuterClose()
            }
            else if (getValues("formAction") === "delete") {
                apiPath=REACT_APP_ENDPOINT_HOST + "/host_delete"
                WriteData(apiPath, data, "delete", setDbWorkComplete, data.userId, props.refresh, props.setRefresh)
                setPostDelete(true)
                setDbWorkComplete(true)
                props.handleOuterClose()
            }
            if (dbWorkComplete) {
                console.log ("back in HostFormCrud dbWorkComplete")
                reset('',
                    {
                    keepValues: true
                })
                props.setDataChange(false)
                // this is a toggle to reset state for GetData to refresh after saves.
                // if (!props.refresh) {
                //     props.setRefresh(true)
                // }
                // else {
                //     props.setRefresh(false)
                // }
            }
        }
        else {
            // means a validation failed
            setTitleMessage("There is a data issue")
            setDbWorkComplete(true)
            setShowInner(true)
        }
    }

    // console.log("in HostFormCRUD dataChange= ", props.dataChange)

    const [hostPhotoLink, setHostPhotoLink] = useState(null)


    if (props.CRUD === "ReadUpdateDelete" && !postDelete) {
         let formValues = props.formData.filter(obj => {
                return obj.user_id === props.hostUserId
        })
        console.log("HostFormCRUD formValue= ", formValues)
        preloadValues = {
            userId: formValues[0].user_id,
            firstName: formValues[0].first_name,
            lastName: formValues[0].last_name,
            displayHostFlag: formValues[0].display_host_flag,
            availableToPublic: formValues[0].available_to_public,
            hostFee: formValues[0].host_fee,
            photoLink: formValues[0].photo_link,
            headline: formValues[0].headline,
            hostMessage: formValues[0].host_message,
            averageRating: formValues[0].average_rating,
            hostedGameCount: formValues[0].hosted_game_count,
            hostTimezone: formValues[0].host_timezone,
            emailId: formValues[0].email_id,
            phoneNumber: formValues[0].phone_number,
            videoLinkIntro: formValues[0].video_link_intro,
            videoLinkExample1: formValues[0].video_link_example_1,
            videoLinkExample2: formValues[0].video_link_example_2,
            videoLinkExample3: formValues[0].video_link_example_3,
            calendarLink: formValues[0].calendar_link,
            facebookLink: formValues[0].facebook_link,
            instagramLink: formValues[0].instagram_link,
            twitterLink: formValues[0].twitter_link,
            tiktokLink: formValues[0].tiktok_link,
            linkedinLink: formValues[0].linkedin_link,
            resumeLink: formValues[0].resume_link,
            paymentLink: formValues[0].payment_link,
            formAction: "read"  // form buttons set this value to 'update', 'insert', 'delete'
        }
        if (hostPhotoLink === null) {
            setHostPhotoLink(preloadValues.photoLink)
        }
    }
    else {
        console.log("HostFormCRID selectedHostValues =", selectedHostValues)
        preloadValues = {
            userId: 0,
            firstName: "",
            lastName: "",
            displayHostFlag: "",
            availableToPublic: "",
            hostFee: null,
            photoLink: "https://s3.us-east-2.amazonaws.com/hostapp.datafiles.utopiaexperiences.cloud/",
            headline: "",
            hostMessage: "",
            averageRating: 0,
            hostedGameCount: 0,
            hostTimezone: "",
            emailId: "",
            phoneNumber: "",
            videoLinkIntro: "",
            videoLinkExample1: null,
            videoLinkExample2: null,
            videoLinkExample3: null,
            calendarLink: "",
            facebookLink: null,
            instagramLink: null,
            twitterLink: null,
            tiktokLink: null,
            linkedinLink: null,
            resumeLink: null,
            paymentLink: null,
            formAction: null,  // form buttons set this value to 'update', 'insert', 'delete'
        }
    }
    console.log ("preloadValues=", preloadValues)
    // for use in changing publish button colors to indicate status and chedking Previewing.
    const [previewDone, setPreviewDone] = useState(false)
    let publishButtonClassName = ""
    if (!preloadValues.displayHostFlag === "Y") {
        publishButtonClassName = "blue-button"
    }
    else {
        publishButtonClassName = "yellow-button"
    }

    const { register, handleSubmit, watch, getValues, setValue, reset,  formState: {dirtyFields}} = useForm({
        defaultValues: preloadValues,
        reValidateMode: 'onChange'
    });

    // watch tracks user input
    watch ()

    useEffect(() => {
        const subscription = watch(() => setDataChange(true))
        return () => subscription.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch]);

    useEffect(() => {
        if (props.CRUD === 'Add' && hostPicked !== false) {
            setValue('userId', selectedHostValues.userId)
            setValue('firstName', selectedHostValues.firstName)
            setValue('lastName', selectedHostValues.lastName)
            setValue('emailId', selectedHostValues.emailId)
            setValue('phoneNumber', selectedHostValues.phoneNumber)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [hostPicked])

    return (
    <div className="form-box">
    <Container fluid className="form-box">
        <MessageModal
            show={showInner}
            handleClose={handleClose}
            titleMessage={titleMessage}
            validationMessage={validationMessage}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
            <Row >
                <Col md="auto">
                <Stack direction="horizontal" gap={4} >
                    {(hostPhotoLink !== null) &&
                        <img className="host-picture-format" src={hostPhotoLink} alt="Host Headshot"/>
                    }
                    {(preloadValues.displayHostFlag === "Y"  || getValues("displayHostFlag") === "Y") &&
                        <div className="green-text">  Published</div>
                     }
                    {(preloadValues.displayHostFlag !== "Y" && getValues("displayHostFlag") !== "Y") &&
                        <div className="blue-text">  Unpublished</div>
                     }
                     {(props.CRUD === "ReadUpdateDelete") &&
                        <div className="blue-text">Scheduled Games:  {gameScheduleCount}</div>
                     }
                     {(props.CRUD === 'Add' && readComplete === false) &&
                        <Spinner animation="grow" size= "sm" role="status" style={{color: "#00447c"}}>
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    }
                    {(props.CRUD === 'Add') &&
                        <SelectNewHostBar
                            refresh={props.refresh}
                            setHostPicked={setHostPicked}
                            setSelectedHostValues={setSelectedHostValues}
                            setReadComplete={setReadComplete}
                        />
                    }
                </Stack>
                </Col>
            </Row>

                <div className = "section-divider">
                     <h4>Basics</h4>
                </div>
            <br/><br/>

            <Row display="table" className="align-items-center justify-content-center">
            <Col md="1">
                <label className="host-label-format">First Name</label>
            </Col>
            <Col md="2">
                <input type="text"
                readOnly
                disabled="disabled"
                placeholder="First Name" className="host-input-format"
                {...register("firstName", {required: true})} />
            </Col>

            <Col md="1" >
                <label className="host-label-format">Last Name</label>
            </Col>
            <Col md="2">
                <input type="text"
                readOnly
                disabled="disabled"
                placeholder="Last Name" className="host-input-format"
                {...register("lastName", {required: true})} />
            </Col>
            <Col md="1" >
                <label className="host-label-format">Email</label>
            </Col>
            <Col md="2">
                <input type="email"
                readOnly
                disabled="disabled"
                placeholder="Email Address" className="host-input-format"
                {...register("emailId", {required: false,  maxLength: 50, pattern: /^\S+@\S+$/i})} />
            </Col>
            <Col md="1" >
                <label className="host-label-format">Phone*</label>
            </Col>
            <Col md="2">
                <input type="tel"
                placeholder="Phone Number"
                className="host-input-format"
                {...register("phoneNumber", {required: true, maxLength: 12, })} />
            </Col>
            </Row>

            <Row display="table" className="align-items-center justify-content-center">
            <Col md="1">
                <label className="host-label-format"> Host Fee*</label>
            </Col>
            <Col md="2">
                <input type="number" placeholder="Host Fee" className="host-input-format"
                {...register("hostFee", {required: true, min: 0, max: 999.99})} />
            </Col>

            <Col md="1" >
                <label className="host-label-format">Rating</label>
            </Col>
            <Col md="2">
                <input type="number" step=".1"  placeholder="Host Rating" className="host-input-format"
                {...register("averageRating", {required: true, max: 5})} />
            </Col>
            <Col md="1" >
                <label className="host-label-format">Tzone(s)*</label>
            </Col>
            <Col md="2">
                <select multiple placeholder="Host Timezone(s)" size="5" className="host-input-format"
                {...register("hostTimezone", {required: true})}>
                 <option value="Eastern">Eastern</option>
                 <option value="Central">Central</option>
                 <option value="Mountain">Mountain</option>
                 <option value="Pacific">Pacific</option>
                 <option value="Hawaii">Hawaii</option>
                </select>
            </Col>
            <Col md="1" >
                <label className="host-label-format">Games</label>
            </Col>
            <Col md="2">
                <input type="number"
                readOnly
                disabled="disabled"
                placeholder="Number of Games Hosted"
                className="host-input-format"
                {...register("hostedGameCount", {maxLength: 12, pattern: /[0-9]{3}-[0-9]{3}-[0-9]{4}/i})} />
            </Col>
            </Row>
            <br/>
            <Row display="table" className="align-items-center justify-content-center">
            <Col md="2" >
                <label className="host-label-format">Photo Link*</label>
            </Col>
            <Col md="8">
                <textarea type="text"  rows="1" placeholder="Paste photo link here" className="host-input-format"
                {...register("photoLink", { required: true, max: 500, maxLength: 2000})} />
            </Col>
            <Col>
            <Button className="yellow-button"
                onClick={() => {
                    setHostPhotoLink(getValues("photoLink"))
                    window.open(getValues("photoLink"))
                }}
                >Verify</Button>
            </Col>
            </Row>
            <Row display="table" className="align-items-center justify-content-center">
            <Col md="2">
                <label className="host-label-format">Calendar Link*</label>
            </Col>
            <Col md="8">
                <textarea type="text"  rows="1" placeholder="Paste Calendly.com link here" className="host-input-format"
                {...register("calendarLink", { required: true, max: 500, maxLength: 2000})} />
            </Col>
            <Col>
            <Button className="yellow-button"
                onClick={() => {
                    window.open(getValues("calendarLink"))
                }}
                >Verify</Button>
            </Col>
            </Row>
            <br/>
            <Row display="table" className="align-items-center justify-content-center">
            <Col md="auto" >
                <label className="host-label-format">UE Ambassador</label>
            </Col>
            <Col md="auto">
            <input type="checkbox" placeholder="avaialableToPublic" name="availableToPublic"
                    {...register("availableToPublic", { required: false})}/>
            </Col>
            </Row>

            <br/><br/>
                <div className = "section-divider">
                     <h4>Host Message</h4>
                </div>
            <br/><br/>

            <Row display="table" className="align-items-center justify-content-center">
            <Col md="3">
                <label className="host-label-format">Headline*</label>
            </Col>
            <Col md="9">
                <input type="text" placeholder="Headline - enclose in quotes" className="host-input-format"
                {...register("headline", {required: true })} />
            </Col>
            </Row>
            <br/>
            <Row>
            <Col md="3">
                <label className="host-label-format">Host Message*</label>
            </Col>
            <Col md="9">
                <textarea type="text"
                    placeholder="Host Message"
                    className="host-input-format"
                    magin="normal"
                        cols="30"
                        rows="5"
                        wrap="on"
                {...register("hostMessage", { required: true})} />
            </Col>
            </Row>
            <br/>

            <br/>
                <div className = "section-divider">
                     <h4>Videos</h4>
                </div>
            <br/><br/>

            <Row className="align-items-center justify-content-center">
            <Col >
                <label className="host-label-format">Video Intro*</label>
            </Col>
            <Col xs={9}>
                <textarea type="text"  rows="1" placeholder="Paste video link here" className="host-input-format"
                {...register("videoLinkIntro", { required: true, max: 500, maxLength: 2000})} />
            </Col>
            <Col>
            <Button className="yellow-button"
                onClick={() => {
                    window.open(getValues("videoLinkIntro"))
                }}
                >Verify</Button>
            </Col>
            </Row>
            <Row className="align-items-center justify-content-center">
            <Col >
                <label className="host-label-format">Additional Video</label>
            </Col>
            <Col xs={9}>
                <textarea type="text"  rows="1" placeholder="Paste video link here" className="host-input-format"
                {...register("videoLinkExample1", { max: 500, maxLength: 2000})} />
            </Col>
            <Col>
            <Button className="yellow-button"
                onClick={() => {
                    window.open(getValues("videoLinkExample1"))
                }}
                >Verify</Button>
            </Col>
            </Row>
            <Row className="align-items-center justify-content-center">
            <Col >
                <label className="host-label-format">Additional Video</label>
            </Col>
            <Col xs={9}>
                <textarea type="text"  rows="1" placeholder="Paste video link here" className="host-input-format"
                {...register("videoLinkExample2", { max: 500, maxLength: 2000})} />
            </Col>
            <Col>
            <Button className="yellow-button"
                onClick={() => {
                    window.open(getValues("videoLinkExample2"))
                }}
                >Verify</Button>
            </Col>
            </Row>
            <Row className="align-items-center justify-content-center">
            <Col >
                <label className="host-label-format">Additional Video</label>
            </Col>
            <Col xs={9}>
                <textarea type="text"  rows="1" placeholder="Paste video link here" className="host-input-format"
                {...register("videoLinkExample3", { max: 500, maxLength: 2000})} />
            </Col>
            <Col>
            <Button className="yellow-button"
                onClick={() => {
                    window.open(getValues("videoLinkExample3"))
                }}
                >Verify</Button>
            </Col>
            </Row>
            <br/><br/>
                <div className = "section-divider">
                     <h4>Social Media & Resume</h4>
                </div>
            <br/><br/>

            <Row className="align-items-center justify-content-center">
            <Col >
                <label className="host-label-format">Facebook Link</label>
            </Col>
            <Col xs={9}>
                <textarea type="text"  rows="1" placeholder="Paste Facebook link here" className="host-input-format"
                {...register("facebookLink", { max: 500, maxLength: 2000})} />
            </Col>
            <Col>
            <Button className="yellow-button"
                onClick={() => {
                    window.open(getValues("facebookLink"))
                }}
                >Verify</Button>
            </Col>
            </Row>
            <Row className="align-items-center justify-content-center">
            <Col >
                <label className="host-label-format">Instagram Link</label>
            </Col>
            <Col xs={9}>
                <textarea type="text"  rows="1" placeholder="Paste Instagram link here" className="host-input-format"
                {...register("instagramLink", { max: 500, maxLength: 2000})} />
            </Col>
            <Col>
            <Button className="yellow-button"
                onClick={() => {
                    window.open(getValues("instagramLink"))
                }}
                >Verify</Button>
            </Col>
            </Row>
            <Row className="align-items-center justify-content-center">
            <Col >
                <label className="host-label-format">Twitter Link</label>
            </Col>
            <Col xs={9}>
                <textarea type="text"  rows="1" placeholder="Paste Twitter link here" className="host-input-format"
                {...register("twitterLink", { max: 500, maxLength: 2000})} />
            </Col>
            <Col>
            <Button className="yellow-button"
                onClick={() => {
                    window.open(getValues("twitterLink"))
                }}
                >Verify</Button>
            </Col>
            </Row>
            <Row className="align-items-center justify-content-center">
            <Col >
                <label className="host-label-format">Tiktok Link</label>
            </Col>
            <Col xs={9}>
                <textarea type="text"  rows="1" placeholder="Paste Tiktok link here" className="host-input-format"
                {...register("tiktokLink", { max: 500, maxLength: 2000})} />
            </Col>
            <Col>
            <Button className="yellow-button"
                onClick={() => {
                    window.open(getValues("tiktokLink"))
                }}
                >Verify</Button>
            </Col>
            </Row>
            <Row className="align-items-center justify-content-center">
            <Col >
                <label className="host-label-format">LinkedIn Link</label>
            </Col>
            <Col xs={9}>
                <textarea type="text"  rows="1" placeholder="Paste LinkedIn link here" className="host-input-format"
                {...register("linkedinLink", { max: 500, maxLength: 2000})} />
            </Col>
            <Col>
            <Button className="yellow-button"
                onClick={() => {
                    window.open(getValues("linkedinLink"))
                }}
                >Verify</Button>
            </Col>
            </Row>
            <Row className="align-items-center justify-content-center">
            <Col >
                <label className="host-label-format">Resume Link</label>
            </Col>
            <Col xs={9}>
                <textarea type="text"  rows="1" placeholder="Enter: https://s3.us-east-2.amazonaws.com/hostapp.datafiles.utopiaexperiences.cloud/ immediately followed by the file name." className="host-input-format"
                {...register("resumeLink", { max: 500, maxLength: 2000})} />
            </Col>
            <Col>
            <Button className="yellow-button"
                onClick={() => {
                    window.open(getValues("resumeLink"))
                }}
                >Verify</Button>
            </Col>
            </Row>
            <br/>

            {/* conditionally render CRUD buttons based on form mode. */}

            <Row className="justify-content-center">

                    <>
                    <Stack direction="horizontal" gap={3} className="d-flex justify-content-center">
                    {(props.CRUD === "ReadUpdateDelete") &&
                    <>
                    <Button  className="blue-button"
                        onClick={() => {
                            if (saveWarning === false && getValues("displayHostFlag") === "Y") {
                                setTitleMessage("This is a published Host")
                                setValidationMessage("This is a reminder that anytime you save host information"
                                + " the host is automatically unpublished.  You will need to Preview your work "
                                + " and then Publish the Host again.")
                                setShowInner(true)
                                setSaveWarning(true)
                            }
                            else {
                                console.log ("Save= ", props.CRUD)
                                setValue("displayHostFlag", "N")
                                setValue("formAction", "update")
                                setPreviewDone(false)
                                handleSubmit(onSubmit)()
                            }
                    }}
                    >Save</Button>
                    <Button className="blue-button"
                        onClick={() => {
                            if (deleteWarning === false) {
                                setTitleMessage("You are about to delete a host")
                                setValidationMessage("Deleting this host will unpublish the host, deactivate their system access and will prevent the host "
                                + "from participating in client games.  Please be certain you want to do this.")
                                setShowInner(true)
                                setDeleteWarning(true)
                                // produce a longer warning message if host has scheduled games
                                if (gameScheduleCount > 0) {
                                    setTitleMessage("You are about to delete a host.   Urgent action will be required.")
                                    setValidationMessage("Deleting this host will unpublish the host, deactivate their system access and will prevent "
                                    + " the host from participating in scheduled client games.  This host has "
                                    + gameScheduleCount + " scheduled game(s).  You will need to immediately go to the Sponsored Host Game Schedule page, look up the host's "
                                    + "scheduled game(s), and notify the impacted commmunities.  Please be certain you want to delete this host.")
                                    setShowInner(true)
                                }
                            }
                            else {
                                setValue("formAction", "delete")
                                handleSubmit(onSubmit)()
                            }
                    }}
                    >Delete</Button>
                    </>
                    }
                    {(props.CRUD === "Add") &&
                    <Button type="submit" className="blue-button"
                            onClick={() => {
                                console.log ("Save= ", props.CRUD)
                                console.log ("beenInserted=", beenInserted)
                                setValue("displayHostFlag", "N")
                                if (beenInserted === false) {
                                    setValue("formAction", "insert")
                                }
                                else {
                                    setValue("formAction", "update")
                                }
                                setPreviewDone(false)
                         }}
                    >Save</Button>
                    }
                    <Link  to= {{
                        key: 'moreabout',
                        pathname: '/moreabouthost',
                        }}
                        target= {"_blank"}
                        rel= {"noreferrer noopener"}
                        >
                        <Button  className="blue-button"
                        onClick={() => {
                            // localStorage, a kludge that needs to be addresssed when react-router v6 is backwards compatible.
                            window.localStorage.setItem('emailId', getValues("emailId"))
                            window.localStorage.setItem('fistName', getValues("firstName"))
                            window.localStorage.setItem('lastName', getValues("lastName"))
                            window.localStorage.setItem('headline', getValues("headline"))
                            window.localStorage.setItem('photoLink', getValues("photoLink"))
                            window.localStorage.setItem('calendarLink', getValues("calendarLink"))
                            window.localStorage.setItem('availableToPublic', getValues("availableToPublic"))
                            window.localStorage.setItem('idToken', window.sessionStorage.getItem('idToken'))
                            setPreviewDone(true)
                            console.log ("Preview Button, dataChange=", dataChange)
                            console.log ("Preview Button, beenInserted=", beenInserted)
                            if (dataChange === true) {
                                setValue("displayHostFlag", "N")
                                if (beenInserted === false && props.CRUD === "Add") {
                                    console.log("doing insert")
                                    setValue("formAction", "insert")
                                    handleSubmit(onSubmit)()
                                }
                                else {
                                    console.log("doing update")
                                    setValue("formAction", "update")
                                    handleSubmit(onSubmit)()
                                }
                            }
                         }}
                        >Preview</Button>
                    </Link>
                    {( preloadValues.displayHostFlag === "Y" || getValues("displayHostFlag") === "Y") &&
                     <Button className="blue-button"
                            onClick={() => {
                                setValue("displayHostFlag", "N")
                                setValue("formAction", "update")
                                handleSubmit(onSubmit)()
                         }}
                    >Unpublish</Button>
                    }
                    {( preloadValues.displayHostFlag !== "Y" && getValues("displayHostFlag") !== "Y") &&
                    <Button className={publishButtonClassName}
                            onClick={() => {
                                if (previewDone === true) {
                                setValue("displayHostFlag", "Y")
                                setPreviewDone(false)
                                setValue("formAction", "update")
                                handleSubmit(onSubmit)()
                                }
                                else {
                                    setTitleMessage("Please preview the Host's page")
                                    setValidationMessage("We need you to review the Host's page before Publishing it.")
                                    setShowInner(true)
                                }
                             }}
                    >Publish</Button>
                    }
                    {(props.CRUD === "Add") &&
                    <Button type="submit" className="blue-button"
                            onClick={() => {
                                console.log ("Save= ", props.CRUD)
                                setValue("displayHostFlag", "N")
                                setValue("userId", 0)
                                setBeenInserted(false)
                                setDataChange(false)
                                reset('',
                                {
                                    keepDefaultValues: true
                                })

                         }}
                    >Clear</Button>
                    }
                    </Stack>
                    </>
                    <p>* Required field</p>
            </Row>
            {(!dbWorkComplete) &&
                    <div>
                    <Spinner animation="border" role="status" style={{color: "#00447c"}}>
                    <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    </div>
            }
        </form>
    </Container>
    </div>

    )

}

export default HostFormCRUD