import {useState, useEffect} from "react";
import axios from "axios";
import axiosRetry from 'axios-retry';

// queries the database for new hosts for HostFormCRUD Add mode dropdown

const GetDropdwonData = (apiPath, refresh, setReadComplete, key) => {

    // console.log ("in GetDropdownData-----------------------------")
    // console.log('apiPath= ', apiPath)
    // console.log ('refresh= ', refresh)
    // console.log ("GetDropdownData key =", key)

    const [newDropdownData, setNewDropdownData] = useState([]);

    let config = {
      headers: {
        'Authorization':  window.sessionStorage.getItem('idToken')
      },
      params: {
         key: key
      },
    }

    axiosRetry(axios, { retries: 3 });

  // Using useEffect to call the API once mounted and get the data
    useEffect(() => {
    (async () => {
      await axios.get(apiPath, config)
      .then (result => {
        setNewDropdownData(result.data);
        setReadComplete(true)
        console.log("GetDropdownData query executed!!!!!!!!!")
      })
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);
  return (newDropdownData)
}

export default GetDropdwonData