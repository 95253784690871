import React from "react";
import { useForm } from "react-hook-form";
import { Container, Row, Col, Button} from "react-bootstrap"
import "./Form.css"
import ueIcon from '../assets/UElogo_whiteback.png'
import publicIcon from '../assets/public-icon-16.jpg'

const HostInfo = (props) => {

    let preloadValues = {}
    let formValues = []

    console.log("contactInfo formData=", props.formData)
    console.log("contactInfo props.keyValue=", props.keyValue)

    formValues = props.formData.filter(obj => {
        return obj.game_schedule_id === props.keyValue
    })
    console.log("hostInfo formValue= ", formValues)

    preloadValues = {

        name: formValues[0].host_name,
        email: formValues[0].host_email_id,
        phone: formValues[0].host_phone_number,

        }

    const { register} = useForm({
        defaultValues: preloadValues
    });

    return (
    <div className="form-box">
    <Container fluid className="form-box">

        <form >

            <Row>
            <Col >
                <label className="label-format">Host Name:</label>
            </Col>
            <Col xs={9}>
                <input type="text" readOnly disabled="disabled" placeholder="Contact Name" className="input-format"
                {...register("name")} />
            </Col>
            </Row>
            <br/>
            <Row>
            <Col >
                <label className="label-format">Email Address:</label>
            </Col>
            <Col xs={9}>
                <input type="text" readOnly disabled="disabled" placeholder="Email Address" className="input-format"
                {...register("email")} />
            </Col>
            </Row>
            <br/>
            <Row>
            <Col >
                <label className="label-format">Phone Number:</label>
            </Col>
            <Col xs={9}>
                <input type="text" readOnly disabled="disabled" placeholder="Phone Number" className="input-format"
                {...register("phone")} />
            </Col>
            </Row>
            <br/>

            <Row className="justify-content-md-center">

                <Col md="auto">

                <Button className="blue-button"
                        onClick={() => {
                        window.open (formValues[0].host_calendar_link, "_blank")
                     }}
                    >Host Calendar</Button>

                </Col>

            </Row>
            <Row className="justify-content-md-center">
                <Col md="auto">
                {(formValues[0].host_available_to_public !== "Y") &&
                    <div><img className="display-icon" src={ueIcon} alt={"UE Logo"}/></div>
                }
                {(formValues[0].host_available_to_public === "Y") &&
                    <div><img className="display-icon" src={publicIcon} alt={"Public Logo"}/></div>
                }
                </Col>
            </Row>

            <Row className="justify-content-md-center">
                <Col md="auto">
                    <div>${formValues[0].host_fee}</div>
                </Col>
            </Row>

            <br/>

        </form>
    </Container>
    </div>

    )

}

export default HostInfo