import './HostSection.css';
import './MoreAboutHost.css';
import React  from "react"
import axios from "axios";
import { useState, useEffect} from "react";
import { Container, Row, Col, Spinner} from 'react-bootstrap';
import MoreAboutHost from "./MoreAboutHost"
import ScheduleButton from './ScheduleButton';


// Copied in (and modified) from the UE Host app repo to allow users preview new Hosts.
// We need something like Bit for React, and will try it when we're making money.
// There is a problem with React-Router V6 (just released)at present and useLocation and
// Link aren't working as the V5 router did in the Host app.  Staying with V6 though in
// the hopes of coming back and removing the local storage use when V6 is made backwards
// compatible with V5.


function SingleHostInformationQuery(emailId, setIsLoaded) {

    const [host, setHosts] = useState([]);
    const source = axios.CancelToken.source();
    const REACT_APP_ENDPOINT_HOST=process.env.REACT_APP_ENDPOINT_HOST;
    const requestPath = REACT_APP_ENDPOINT_HOST + "/host"
    let config = {
        headers: {
          'Authorization':  window.localStorage.getItem('idToken')
        },
        params: {
            emailId: emailId
        },
      }
    console.log("SingleHostInformationQuery requestPath= ", requestPath)

    const getHost = () => {
    axios.get(requestPath, config)
    // {
    //     params: { emailId: emailId} })
        .then((res) => {
            console.log("res.data=", res.data)
            setHosts(res.data);
            source.cancel();
            setIsLoaded(true)
      });
    };

    // Note:  The below useEffect produces development compiler warning. Okay to ignore.

    useEffect(() => {
        console.log("in UseEffect")
        getHost();
        // eslint-disable-next-line react-hooks/exhaustive-deps
     }, []);
    return (host)
}

function createMoreAboutHost (host) {

    return (
        <MoreAboutHost
            key = {host.user_id}
            userId = {host.user_id}
            firstName = {host.first_name}
            lastName = {host.last_name}
            availableToPublic = {host.available_to_public}
            paidHostFlag = {host.paid_host_flag}
            hostFee = {host.host_fee}
            photoLink = {host.photo_link}
            headline = {host.headline}
            hostMessage = {host.host_message}
            averageRating = {host.average_rating}
            hostedGameCount = {host.hosted_game_count}
            hostTimezone = {host.host_timezone}
            emailId = {host.email_id}
            phoneNumber = {host.phone_number}
            videoLinkIntro = {host.video_link_intro}
            videoLinkExample1 = {host.video_link_example_1}
            videoLinkExample2 = {host.video_link_example_2}
            videoLinkExample3 = {host.video_link_example_3}
            calendarLink = {host.calendar_link}
            facebookLink = {host.facebook_link}
            instagramLink = {host.instagram_link}
            tiktokLink= {host.tiktok_link}
            linkedInLink = {host.linkedin_link}
            resumeLink = {host.resume_link}
            paymentLink = {host.payment_link}
            />
    )
}

const MoreAboutHostContainer = () => {

    // const location = useLocation();  //This stopped working in React-Router V6
    // let emailId = location.emailId
    // let firstName = location.firstName
    // let lastName = location.lastName
    // let headline = location.headline
    // let photoLink = location.photoLink
    // let calendarLink = location.calendarLink
    // let availableToPublic = location.availableToPublic

    let emailId = window.localStorage.getItem('emailId')
    let firstName = window.localStorage.getItem('fistName')
    let lastName = window.localStorage.getItem('lastName')
    let headline = window.localStorage.getItem('headline')
    let photoLink = window.localStorage.getItem('photoLink')
    let calendarLink = window.localStorage.getItem('calendarLink')
    let availableToPublic = window.localStorage.getItem('availableToPublic')

    console.log("MoreAboutHostContainer emailId= ", emailId)

    // isLoaded is used by for a spinner.  SingleHostInformationQuery sets the status when done.
    const [isLoaded, setIsLoaded] = useState(false);
    let host = []
    host = SingleHostInformationQuery(emailId, setIsLoaded)
    console.log ("MoreAboutHosts host=", host)
    let alt = firstName + ' ' + lastName + ' photo';

    return (
        <section  id="host-background">
        <Container fluid className="host-page-title-container">
        <Container fluid className="title-box " >
            <Row className="dflex align-items-center">
                <Col lg={true} >
                    <img className="host-photo-more" src={photoLink} alt={alt}/>
                </Col>
                <Col lg={true}  >
                 <h1 >  {firstName} {lastName}  </h1> <br></br>
                    <h3>{headline}</h3><br></br>
                    <ScheduleButton firstName = {firstName}
                                    calendarLink={calendarLink}
                                    photoLink={photoLink}
                                    availableToPublic={availableToPublic} />
                </Col>
                {(!isLoaded) &&
                <div> <br/>
                <Spinner animation="border" role="status" style={{color: "#00603a"}}>
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
                </div>
                }
            </Row>
        </Container>
        </Container>
        <Container fluid >
            <div >
                {host.map(createMoreAboutHost)}
            </div>
        </Container>
        </section>

    )
}

export default MoreAboutHostContainer