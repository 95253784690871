// thanks to tannerlinsley / react-table / master /examples/virtualized-rows
import React, {useState} from "react";
import { useTable, useGlobalFilter, useFlexLayout, useResizeColumns, useRowSelect } from 'react-table'
import { useExportData } from "react-table-plugins";
import Papa from "papaparse";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { FixedSizeList } from 'react-window'
import { Row, Col, Button, Stack, Spinner} from "react-bootstrap"
import scrollbarWidth from '../utils/scrollbarWidth'
import InformationModal from "./InformationModal";
import setDate from "../utils/setDate";
import WriteData from "../utils/WriteData";


const DataExportTable = ({
    columns,
    data,
    caller,
    setRefresh,
    refresh,
    setShow,
    show,
    valueSelected,
    modalType }) => {

  console.log ("Table data= ", data)
  console.log ("caller=", caller)


  const [refreshTable, setRefreshTable] = useState(false)
  const [dbWorkComplete, setDbWorkComplete] = useState(false)
  let insertPayablesPath = ""
  let updatePayablesPath = ""
  let insertPayablesArray = []
  let updatePayablesArray = []
  let insertPayablesFlag = false
  let updatePayablesFlag = false

  const handleClose = () => setShow(false);

  // extractData produces CSV files and updates the database to record extracts

  function extractData (rows, refresh, setRefresh, setDbWorkComplete) {

    const REACT_APP_ENDPOINT_ACCOUNTING=process.env.REACT_APP_ENDPOINT_ACCOUNTING;
    insertPayablesPath = REACT_APP_ENDPOINT_ACCOUNTING + "/insert_payable_extract"
    updatePayablesPath = REACT_APP_ENDPOINT_ACCOUNTING + "/update_payable_extract"

    console.log("display data=", rows)
    // prepare data for extract and database logging

    if (caller === "Sponsored Host Payables") {

      for (let i = 0; i < rows.length; i++) {

        rows[i].values.extract_date = setDate ()
        rows[i].original.extract_date = setDate()

        if (rows[i].original.host_fee !== rows[i].original.extract_fee){
          rows[i].original.host_fee = rows[i].original.extract_fee
        }

        if (rows[i].original.payable_schedule_id === null) {
          insertPayablesFlag = true
          rows[i].original.payable_schedule_id = rows[i].original.game_schedule_id
          // rows.[i].original.audit_fee = rows.[i].original.host_fee
          rows[i].original['createdBy'] = window.sessionStorage.getItem('userId')
          insertPayablesArray.push(rows[i].original)
          }
          else {
            updatePayablesFlag = true
            if (rows[i].original.audit_fee !== rows[i].original.extract_fee){
              // rows[i].original.audit_fee = rows[i].original.extract_fee
              rows[i].original.audit_date = null
            }
            rows[i].original['updatedBy'] = window.sessionStorage.getItem('userId')
            console.log("updated rows[i].original.host_fee=", rows[i].original.host_fee)
            updatePayablesArray.push(rows[i].original)
          }
        }
    } else if (caller === "Host Charges Review") {
        for (let i = 0; i < rows.length; i++) {

          if (rows[i].original.audit_date === null) {
            rows[i].values.audit_date = setDate ()
            rows[i].original.audit_date = setDate()

              if (rows[i].original.host_fee !== rows[i].original.extract_fee){
                rows[i].original.host_fee = rows[i].original.extract_fee
              }

              if (rows[i].original.payable_schedule_id === null) {
                insertPayablesFlag = true
                rows[i].original.host_fee = null
                rows[i].original.extract_date = null
                rows[i].original.payable_schedule_id = rows[i].original.game_schedule_id
                rows[i].original['createdBy'] = window.sessionStorage.getItem('userId')
                insertPayablesArray.push(rows[i].original)
                }
                else {
                  updatePayablesFlag = true
                  rows[i].original.audit_fee = rows[i].original.host_fee
                  rows[i].original['updatedBy'] = window.sessionStorage.getItem('userId')
                  updatePayablesArray.push(rows[i].original)
                }
          }
        }
    }

        // export to CSV file
        exportData("csv", false)

        console.log("insertPayablesArray=", insertPayablesArray)
        if (insertPayablesFlag === true ) {
        WriteData(
            insertPayablesPath,
            insertPayablesArray,
            "post",
            setDbWorkComplete,
            null,
            refresh,
            setRefresh
        )
        insertPayablesFlag = false
    }
    console.log("updatePayablesArray=", updatePayablesArray)
        if (updatePayablesFlag === true ) {
        WriteData(
            updatePayablesPath,
            updatePayablesArray,
            "put",
            setDbWorkComplete,
            null,
            refresh,
            setRefresh
        )
        updatePayablesFlag = false
    }

        // force refresh
        if (refreshTable === false ) {
        setRefreshTable(true)
        } else {
        setRefreshTable(false)
        }
  }

  function getExportFileBlob({ columns, data, fileType, fileName }) {

    let title = caller + " - " + setDate()

    if (fileType === "csv") {
      // CSV export
      const headerNames = columns.map((col) => col.exportValue);
      columns.push("client_billing_name")
      console.log("columns=", columns)
      console.log("headerNames=", headerNames)
      const csvString = title+'\n\n'+Papa.unparse({ fields: headerNames, data });
      return new Blob([csvString], { type: "text/csv" });
    }
    //PDF export
    if (fileType === "pdf") {
      const headerNames = columns.map((column) => column.exportValue);
      const doc = new JsPDF('l');
      doc.autoTable({
        setFontSize: 20,
        setTextColor: 40,
        startY: 30,
        head: [headerNames],
        body: data,
        margin: { top: 30 },
        styles: {
          minCellHeight: 9,
          halign: "left",
          valign: "center",
          fontSize: 11,
          showHead: "everyPage",
          overflow: "linebreak"
        },
        didDrawPage: function (data) {

          // Header
          doc.setFontSize(20);
          doc.setTextColor(40);
          doc.text(title, data.settings.margin.left, 22);

          // Footer
          var str = "Page " + doc.internal.getNumberOfPages();

          doc.setFontSize(10);

          // jsPDF 1.4+ uses getWidth, <1.4 uses .width
          var pageSize = doc.internal.pageSize;
          var pageHeight = pageSize.height
            ? pageSize.height
            : pageSize.getHeight();
          doc.text(str, data.settings.margin.left, pageHeight - 10);
        }
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }

    // Other formats goes here
    return false;
  }



  // Use the state and functions returned from useTable to build UI

//   const defaultColumn = React.useMemo(
//     () => ({
//       width: 150,
//     }),
//     []
//   )

    const defaultColumn = React.useMemo(
        () => ({
        // When using the useFlexLayout:
        minWidth: 30, // minWidth is only used as a limit for resizing
        width: 150, // width is used for both the flex-basis and flex-grow
        maxWidth: 200, // maxWidth is only used as a limit for resizing
        }),
        []
    )

  const scrollBarSize = React.useMemo(() => scrollbarWidth(), [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    exportData
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useFlexLayout,
    useResizeColumns,
    useRowSelect,
    useGlobalFilter
  )

  const handleFilterInputChange = (event) => {
    const { value } = event.currentTarget;
    setGlobalFilter(value);
  };

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index]
      prepareRow(row)
      return (
        <div
          {...row.getRowProps({
            style,
          })}
          className="tr"
        >
          {row.cells.map(cell => {
            return (
              <div {...cell.getCellProps()} className="td">
                {cell.render('Cell')}
              </div>
            )
          })}
        </div>
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [prepareRow, rows]
  )

//   // used by Sponsored Host Ratings
//   const average = React.useMemo(
//     () =>
//         rows.reduce((sum, row) => Number(row.values.average_rating) + sum, 0) / rows.length,
//     [rows]
//   )

  // Render the UI for your table
  return (

    <div className=" align-item-center justify-content-center">

    <div className="d-flex align-items-center justify-content-center"
        style={{
        marginTop: "-30px",
        marginBottom: "30px",
        }}
        >

    <button className="blue-button"
        style={{
        color: 'white',
        }}
        onClick={() => {
        if (caller === "Client Billing Master") {
        }
        else if (caller === "Host Charges Review") {
            exportData("csv", false);
        }
        }}
    >
        Export Current View as CSV
    </button>{" "}

    <button className="blue-button"
        style={{
        color: 'white',
        }}
        onClick={() => {
        exportData("pdf", false);
        }}
    >
        Export Current View as PDF
    </button>
    </div>

    {/* <Row className="text-center align-items-center justify-content-center"><Col> */}
    <input placeholder="Enter filter value"
        style={{
            height: "40px",
            width: "100%",
            display: "inline-flex",
            // flex: "1",
            textAlign: "center",
            border: "1px solid #ccc",
            // marginTop: "-300px",
            marginBottom: "0px"}}
        onChange={handleFilterInputChange} />
    <br/>
    {/* </Col>
    </Row> */}



    <div {...getTableProps()} className="table">

      <InformationModal
            show={show}
            handleClose={handleClose}
            caller={caller}
            formData={data}
            selectedValue={valueSelected}
            modalType={modalType}
        />
      <div>
        {headerGroups.map(headerGroup => (
          <div {...headerGroup.getHeaderGroupProps()} className="tr">
            {headerGroup.headers.map(column => (
              <div {...column.getHeaderProps()} className="th">
                {column.render('Header')}
              </div>
            ))}
          </div>
        ))}
      </div>

      <div {...getTableBodyProps()}>
        <FixedSizeList
          height={600}
          itemCount={rows.length}
          itemSize={35}
        //   width={totalColumnsWidth+scrollBarSize}
        >
          {RenderRow}
        </FixedSizeList>
      </div>
    </div>
    </div>





  )
}



export default DataExportTable