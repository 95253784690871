import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Container, Row, Col, Button, Stack, Spinner} from "react-bootstrap"
import MessageModal from "./MessageModal"
import doValidations from "../utils/doValidations";
import WriteData from "../utils/WriteData";
import "./Form.css"

const TrainingFormCRUD = (props) => {

    console.log("in TrainingFormCrud")
    const setDataChange = props.setDataChange // descructure for useEffect
    // console.log("props.setRefresh=", props.setRefresh)
    // console.log("TrainingFormCrud formData= ", props.formData)
    console.log("TrainingFormCRUD sectionValue = ", props.trainingId)

    const REACT_APP_ENDPOINT_TRAINING_MAINT=process.env.REACT_APP_ENDPOINT_TRAINING_MAINT;
    let apiPath = ''

    const [dbWorkComplete, setDbWorkComplete] = useState(true);
    const [postDelete, setPostDelete] = useState(false);  //to prevent a preLoadValues load in rerender after record deletion.

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [titleMessage, setTitleMessage] = useState(null)
    const [validationMessage, setValidationMessage] = useState(null)

    const [deleteWarning, setDeleteWarning] = useState(false)
    const [validationLookup, setValidationLookup] = useState(false)


    let preloadValues = {}

    const onSubmit = async data => {
        console.log(data);
        setDbWorkComplete(false)
        console.log ("formAction=", getValues("formAction"))
        let passValidation = true
        if (getValues("formAction") === "insert" || getValues("formAction") === "update") {
            passValidation = await doValidations("TrainingFormCRUD", getValues("formAction"), dirtyFields, data, props.formData, setValidationMessage)
        }
        if (passValidation) {
            if (getValues("formAction") === "insert") {
                apiPath=REACT_APP_ENDPOINT_TRAINING_MAINT + "/training_video_insert"
                WriteData(apiPath, data, "post", setDbWorkComplete)
            }
            else if (getValues("formAction") === "update") {
                apiPath=REACT_APP_ENDPOINT_TRAINING_MAINT + "/training_video_update"
                WriteData(apiPath, data, "put", setDbWorkComplete, props.trainingId, props.refresh, props.setRefresh)
                props.handleOuterClose()
            }
            else if (getValues("formAction") === "delete") {
                apiPath=REACT_APP_ENDPOINT_TRAINING_MAINT + "/training_video_update"
                WriteData(apiPath, data, "delete", setDbWorkComplete, props.trainingId,  props.refresh, props.setRefresh)
                setPostDelete(true)
                setDbWorkComplete(true)
                props.handleOuterClose()
            }
            if (dbWorkComplete) {
                console.log ("back in TrainingFormCrud dbWorkComplete")
                reset({
                    trainingId: "",
                    category: "",
                    sortKey: "",
                    name: "",
                    description: "",
                    videoLink: "",
                    },
                    {
                        keepDefaultValue: true,
                    })
                props.setDataChange(false)
             }
        }
        else {
            console.log('validationMessage=', validationMessage)
            setTitleMessage("There's a data issue")
            setDbWorkComplete(true)
            setShow(true)
        }
    }

    // console.log("in to TrainingFormCRUD dataChange= ", props.dataChange)

    if (props.CRUD === "ReadUpdateDelete" && !postDelete) {
         let formValues = props.formData.filter(obj => {
                return obj.training_id === props.trainingId
        })
        console.log("TrainingFormCRUD formValue= ", formValues)
        preloadValues = {
            trainingId: formValues[0].training_id,
            category: formValues[0].category,
            sortKey: formValues[0].sort_key,
            name: formValues[0].name,
            description: formValues[0].description,
            videoLink: formValues[0].video_link,
            formAction: "read"  // form buttons set this value to 'update', 'insert', 'delete'
        }
    }
    else {
        preloadValues = {
            trainingId: "",
            category: "",
            sortKey: "",
            name: "",
            description: "",
            videoLink: "",
            formAction: "read"  // form buttons set this value to 'update', 'insert', 'delete'
        }
        }

    const { register, handleSubmit, watch, getValues, setValue, reset, formState: {dirtyFields}} = useForm({
        defaultValues: preloadValues
    });

    // watch tracks user input
    watch ()

    useEffect(() => {
        const subscription = watch(() => setDataChange(true))
        return () => subscription.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch]);

    return (
    <div className="form-box">
    <Container fluid className="form-box">
        <MessageModal
            show={show}
            handleClose={handleClose}
            titleMessage={titleMessage}
            validationMessage={validationMessage}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
            <Col >
                <label className="label-format">Module*</label>
            </Col>
            <Col xs={9}>
                <input type="text" placeholder="Module name" className="input-format"
                {...register("category", {required: true})} />
            </Col>
            </Row>
            <br/>
            <Row>
            <Col >
                <label className="label-format">Sort Key*</label>
            </Col>
            <Col xs={9}>
                <input type="text" placeholder="Enter a sort key value to define the order of this item within the module" className="input-format"
                {...register("sortKey", {required: true })} />
            </Col>
            </Row>
            <br/>
            <Row >
            <Col >
                <label className="label-format">Video Name*</label>
            </Col>
            <Col xs={9}>
                <input type="text" placeholder="Name of the training video" className="input-format"
                {...register("name", {required: true})} />
            </Col>
            </Row>
            <br/>
            <br/>
            <Row >
            <Col >
                <label className="label-format">Description*</label>
            </Col>
            <Col xs={9}>
                <textarea placeholder="Enter a description of the video.  Two lines maximum"
                className="input-format"
                magin="normal"
                cols="30"
                rows="2"
                wrap="on"
                {...register("description", {required: true, maxLength: 192})} />
            </Col>
            </Row>
            <br/>
            <Row >
            <Col >
                <label className="label-format">Video Link*</label>
            </Col>
            <Col xs={9}>
                <input type="text" placeholder="Paste training video link here" className="input-format"
                {...register("videoLink", {required: true })} />
            </Col>
            </Row>
            <br/>

            {/* conditionally render CRUD buttons based on form mode. */}

            <Row className="justify-content-center">


                    <>
                    <Stack direction="horizontal" gap={3} className="d-flex justify-content-center">
                    {(validationLookup === false) &&
                    <Button className="yellow-button"
                        onClick={() => {
                            window.open(getValues("videoLink"), "_blank")
                            setValidationLookup(true)
                         }}
                    >Validate</Button>
                    }
                    {(validationLookup === true) &&
                        <Button className="blue-button"
                        onClick={() => {
                            window.open(getValues("videoLink"), "_blank")
                            setValidationLookup(true)
                         }}
                    >Validate</Button>
                    }
                    {(props.CRUD === "ReadUpdateDelete") &&
                    <>
                    <Button className="blue-button"
                        onClick={() => {
                            if (validationLookup === false) {
                                setTitleMessage ("Please validate the Video Link")
                                setValidationMessage(" ")
                                setShow(true)
                            }
                            else {
                                setValue("formAction", "update")
                                handleSubmit(onSubmit)()
                            }
                        }}
                    >Save</Button>
                    <Button className="blue-button"
                        onClick={() => {
                            if (deleteWarning === false) {
                                setTitleMessage("You are about to delete a Training Video")

                                setValidationMessage("This will immedediately remove this video from our training page. "
                                + "If you're sure you want to make this change, please click on the Delete button a second time."
                                )
                                setShow(true)
                                setDeleteWarning(true)
                            }
                            else {
                                setValue("formAction", "delete")
                                handleSubmit(onSubmit)()
                            }
                    }}
                    >Delete</Button>
                    </>
                    }
                    {(props.CRUD === "Add") &&
                    <Button className="blue-button"
                            onClick={() => {
                                if (validationLookup === false) {
                                setTitleMessage ("Please validate the Video Link")
                                setValidationMessage(" ")
                                setShow(true)
                            }
                            else {
                                setValue("formAction", "insert")
                                setValidationLookup(false)
                                handleSubmit(onSubmit)()
                            }
                         }}
                    >Save</Button>
                    }
                    </Stack>
                    </>
                    <p>* Required field</p>
            </Row>
            {(!dbWorkComplete) &&
                    <div>
                    <Spinner animation="border" role="status" style={{color: "#00447c"}}>
                    <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    </div>
            }
        </form>
    </Container>
    </div>

    )

}

export default TrainingFormCRUD