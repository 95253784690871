
import React, {useState, useRef, useEffect} from "react";
import { useTable, useGlobalFilter, useBlockLayout, useSortBy, useFilters  } from 'react-table'
import { useExportData } from "react-table-plugins";
import Papa from "papaparse";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { FixedSizeList } from 'react-window'
import DatePicker from "../utils/DatePickerCalendar";
// import { AiOutlineSync, } from "react-icons/ai";
import { AiFillWindows, AiOutlineFilter } from "react-icons/ai";
import scrollbarWidth from '../utils/scrollbarWidth'
import InformationModal from "./InformationModal";
import ModalContainer from "./ModalContainer"
import setDate from "../utils/setDate";
import WriteData from "../utils/WriteData";
import "./Form.css"
import { isCompositeComponent } from "react-dom/test-utils";

const ReceivablesTable = ({
    columns,
    data,
    caller,
    show,
    setShow,
    valueSelected,
    setValueSelected,
    modalType,
    setModalType,
    refresh,
    setRefresh,
    CRUD,
    setCRUD,
    currentData,
    setCurrentData,
  }) => {


  const [refreshTable, setRefreshTable] = useState(false)
  const [dbWorkComplete, setDbWorkComplete] = useState(false)

  let insertPayablesPath = ""
  let updatePayablesPath = ""
  let insertPayablesArray = []
  let updatePayablesArray = []
  let insertPayablesFlag = false
  let updatePayablesFlag = false

  console.log ("Table data= ", data)
  console.log ("caller=", caller)

  // // force a refresh on return
  // if (refresh === "true") {
  //   setRefresh(false)
  // } else {
  //   setRefresh(true)
  // }

  // for use with modals

  const handleClose = () => setShow(false);

  // extractData produces CSV files and updates the database to record extracts

  function extractData (rows, refresh, setRefresh, setDbWorkComplete) {

    const REACT_APP_ENDPOINT_ACCOUNTING=process.env.REACT_APP_ENDPOINT_ACCOUNTING;
    insertPayablesPath = REACT_APP_ENDPOINT_ACCOUNTING + "/insert_payable_extract"
    updatePayablesPath = REACT_APP_ENDPOINT_ACCOUNTING + "/update_payable_extract"

    console.log("display data=", rows)
    // prepare data for extract and database logging

    if (caller === "Sponsored Host Payables") {

      for (let i = 0; i < rows.length; i++) {

        rows[i].values.extract_date = setDate ()
        rows[i].original.extract_date = setDate()

        if (rows[i].original.host_fee !== rows[i].original.extract_fee){
          rows[i].original.host_fee = rows[i].original.extract_fee
        }

        if (rows[i].original.payable_schedule_id === null) {
          insertPayablesFlag = true
          rows[i].original.payable_schedule_id = rows[i].original.game_schedule_id
          // rows.[i].original.audit_fee = rows.[i].original.host_fee
          rows[i].original['createdBy'] = window.sessionStorage.getItem('userId')
          insertPayablesArray.push(rows[i].original)
          }
          else {
            updatePayablesFlag = true
            if (rows[i].original.audit_fee !== rows[i].original.extract_fee){
              // rows[i].original.audit_fee = rows[i].original.extract_fee
              rows[i].original.audit_date = null
            }
            rows[i].original['updatedBy'] = window.sessionStorage.getItem('userId')
            console.log("updated rows[i].original.host_fee=", rows[i].original.host_fee)
            updatePayablesArray.push(rows[i].original)
          }
        }
    } else if (caller === "Host Charges Review") {
        for (let i = 0; i < rows.length; i++) {

          if (rows[i].original.audit_date === null) {
            rows[i].values.audit_date = setDate ()
            rows[i].original.audit_date = setDate()

              if (rows[i].original.host_fee !== rows[i].original.extract_fee){
                rows[i].original.host_fee = rows[i].original.extract_fee
              }

              if (rows[i].original.payable_schedule_id === null) {
                insertPayablesFlag = true
                rows[i].original.host_fee = null
                rows[i].original.extract_date = null
                rows[i].original.payable_schedule_id = rows[i].original.game_schedule_id
                rows[i].original['createdBy'] = window.sessionStorage.getItem('userId')
                insertPayablesArray.push(rows[i].original)
                }
                else {
                  updatePayablesFlag = true
                  rows[i].original.audit_fee = rows[i].original.host_fee
                  rows[i].original['updatedBy'] = window.sessionStorage.getItem('userId')
                  updatePayablesArray.push(rows[i].original)
                }
          }
        }
    }

    // export to CSV file
    exportData("csv", false)

    console.log("insertPayablesArray=", insertPayablesArray)
    if (insertPayablesFlag === true ) {
      WriteData(
        insertPayablesPath,
        insertPayablesArray,
        "post",
        setDbWorkComplete,
        null,
        refresh,
        setRefresh
      )
      insertPayablesFlag = false
   }
   console.log("updatePayablesArray=", updatePayablesArray)
    if (updatePayablesFlag === true ) {
      WriteData(
        updatePayablesPath,
        updatePayablesArray,
        "put",
        setDbWorkComplete,
        null,
        refresh,
        setRefresh
      )
      updatePayablesFlag = false
   }

    // force refresh
    if (refreshTable === false ) {
      setRefreshTable(true)
    } else {
      setRefreshTable(false)
    }
  }

// file export functions csv is used by exportData function, pdf is called directly from button

function getExportFileBlob({ columns, data, fileType, fileName }) {

  let title = caller + " - " + setDate()

  if (fileType === "csv") {
    // CSV export
    const headerNames = columns.map((col) => col.exportValue);
    columns.push("client_billing_name")
    console.log("columns=", columns)
    console.log("headerNames=", headerNames)
    const csvString = title+'\n\n'+Papa.unparse({ fields: headerNames, data });
    return new Blob([csvString], { type: "text/csv" });
  }
  //PDF export
  if (fileType === "pdf") {
    const headerNames = columns.map((column) => column.exportValue);
    const doc = new JsPDF('l');
    doc.autoTable({
      setFontSize: 20,
      setTextColor: 40,
      startY: 30,
      head: [headerNames],
      body: data,
      margin: { top: 30 },
      styles: {
        minCellHeight: 9,
        halign: "left",
        valign: "center",
        fontSize: 11,
        showHead: "everyPage",
        overflow: "linebreak"
      },
      didDrawPage: function (data) {

        // Header
        doc.setFontSize(20);
        doc.setTextColor(40);
        doc.text(title, data.settings.margin.left, 22);

        // Footer
        var str = "Page " + doc.internal.getNumberOfPages();

        doc.setFontSize(10);

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        doc.text(str, data.settings.margin.left, pageHeight - 10);
      }
    });
    doc.save(`${fileName}.pdf`);

    return false;
  }

  // Other formats goes here
  return false;
}



// Define a default UI for filtering

function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length;

    return (
      <input
        style={{
          width: '110px',
        }}
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`${count} records...`}
        // placeholder={'Filter'}
      />
    );
  }
  // const defaultColumn = React.useMemo(
  //   () => ({
  //    width: 150,
  //    Filter: DefaultColumnFilter,
  //    }),
  //   [],
  // )

  // filter definitions and variables

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleFilterInputChange = (event) => {
    const { value } = event.currentTarget;
    setGlobalFilter(value);
  };

  const filterTypes = React.useMemo(() => ({
    dateFilter: (rows, id, filterValue) => {
        return rows = rows.filter(row => {
            return new Date(row.values.actual_start_date) >= filterValue[0] && new Date(row.values.actual_start_date) <= filterValue[1];
        });
    },
  }),
    []
  )

  //called when a user selects filter start-date
  const handleStartDate = (date) => {
    setStartDate(date);
    console.log("startDate set =", startDate)
  }

  //called when a user selects filter end-date
  const handleEndDate = (date) => {
      setEndDate(date);
      console.log("endDate set =", endDate)
      // handleFilterByDate()
  }

  // Apply date filters
  const applyDateFilter = () => {
    if (startDate && endDate) {
      setFilter("actual_start_date", [startDate, endDate]);
    }
  }

  const defaultColumn = {
    width: 150,
    Filter: DefaultColumnFilter,
  };

  function transformRef (rowsArray) {
    console.log ('-----transformRef array=', rowsArray)
    return rowsArray
  }

  const scrollBarSize = React.useMemo(() => scrollbarWidth(), [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    totalColumnsWidth,
    prepareRow,
    setGlobalFilter,
    setFilter,
    exportData,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      getExportFileBlob,
      filterTypes,
      initialState: {
        hiddenColumns: ["client_billing_name", ],
      },
    },
    useBlockLayout,
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExportData
  )

  const selectedRows = useRef([]);
  useEffect (() => {
    selectedRows.current = rows.map(({original}) => original)
    transformRef(selectedRows.current)
    console.log("selectedRows.current=", selectedRows.current)
    if (selectedRows.current.length > 0) {
      window.sessionStorage.removeItem("currentData")
      window.sessionStorage.setItem("currentData", JSON.stringify(selectedRows.current))
    }
  },[rows])
  console.log("selectedRows original=", selectedRows)
  console.log("-----rows=", rows)

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index]
      prepareRow(row)
      return (
        <div
          {...row.getRowProps({
            style,
          })}
          className="tr"
          onClick={() => {
            if (caller === "Client Billing Master" ) {
              if (row.original.community_id === null) {
                setCRUD("Add")
                setValueSelected(row.original.community_community_id)
            } else {
                setCRUD("ReadUpdateDelete")
                setValueSelected(row.original.community_id)
            }
            console.log("ReceivablesTable valueSelected=", valueSelected)
            console.log(row.original)
            setShow(true)
            }
            }
          }
        >
          {row.cells.map(cell => {
            return (
              <div {...cell.getCellProps()} className="td">
                {cell.render('Cell')}
              </div>
            )
          })}
        </div>
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [prepareRow, rows]
  )

    const total = React.useMemo(
      () =>
          rows.reduce((sum, row) => row.values.sum_field + sum, 0),
      [rows]
    )


  // Render the UI for the table
  return (
    <div >

    {(caller === "Client Billing") &&
        <div className="d-flex align-items-center justify-content-center">
            {/* <span className="filtered-total">Total:  ${total}</span> */}
            <span className="filtered-total">Total:  ${isNaN(total) ? 0 : total}</span>

            <br/><br/>
        </div>
    }
    <div className="d-flex align-items-center justify-content-center">

    {(caller === "Client Billing Master") &&
    <button className="blue-button"
        style={{
          color: 'white',
        }}
        onClick={() => {
            // setCurrentData(selectedRows.current)
            // window.sessionStorage.setItem("currentData", JSON.JSON.stringify(selectedRows.current))
            console.log("------currentData=", currentData)
            setModalType("Export")
            setCRUD("Export")
            setShow(true)
          }
        }
      >
        Prepare Data Export
      </button>
    }

    {(caller === "Client Billing") &&
      <>
        <button className="blue-button"
            style={{
              color: 'white',
            }}
            onClick={() => {
              if (caller === "Client Billing Master") {
              }
              else if (caller === "Host Charges Review") {
                exportData("csv", false);
              }
            }}
          >
            Export Current View as CSV
          </button>{" "}

          <button className="blue-button"
            style={{
              color: 'white',
            }}
            onClick={() => {
              exportData("pdf", false);
            }}
          >
            Export Current View as PDF
          </button>
          </>
    }
      </div>
      <br/>
      {(caller === "Client Billing") &&
        <>
        <div className="d-flex align-items-center justify-content-center filterParameters" id="filterParameters">
            <div className="datePickerWrapper">

                <span className="startDate">From:  <span className="datePickerLabel">{startDate ? startDate.toLocaleDateString() : null}</span>
                    < DatePicker handleDateChange={handleStartDate} date={startDate} />
                </span>

                <span className="endDate">To: <span className="datePickerLabel">{endDate ? endDate.toLocaleDateString() : null}</span>
                    < DatePicker handleDateChange={handleEndDate} date={endDate} />
                </span>

                 <span className="applyFilter" onClick={ applyDateFilter}><span>Filter  </span>
                    <AiOutlineFilter className="applyFilter_icon" />
                </span>
            </div>
            </div>
            <br/>
            </>
        }
      <input placeholder="Enter global filter value"
        style={{height: "40px",
            width: "1168px",
            textAlign: "center",
            border: "1px solid #ccc",
            marginTop: "-400px",
            marginBottom: "10px"}}
            onChange={handleFilterInputChange}
        />
        <br/>

      {(caller === "Client Billing Master") &&
        <ModalContainer
                show={show}
                handleClose={handleClose}
                caller={caller}
                formData={data}
                selectedValue={valueSelected}
                CRUD={CRUD}
                setRefresh={setRefresh}
                refresh={refresh}
            />
      }

      {(caller === "Client Billing") &&
        <InformationModal
                show={show}
                handleClose={handleClose}
                caller={caller}
                formData={data}
                selectedValue={valueSelected}
                modalType={modalType}
            />
      }

    <div {...getTableProps()} className="table">

      <div>
        {headerGroups.map(headerGroup => (
          <div {...headerGroup.getHeaderGroupProps()} className="tr">
            {headerGroup.headers.map(column => (

              <div {...column.getHeaderProps()} className="th">
                  <span {...column.getSortByToggleProps()}>
                    {column.render('Header')}
                  </span>
                  <div>
                    {column.canFilter ? column.render("Filter") : null}
                  </div>
                </div>
            ))}
          </div>
        ))}
      </div>

      <div {...getTableBodyProps()}>
        <FixedSizeList
          height={500}
          itemCount={rows.length}
          itemSize={35}
          width={totalColumnsWidth+scrollBarSize}
        >
          {RenderRow}
        </FixedSizeList>
      </div>
    </div>
    </div>

  )
}



export default ReceivablesTable