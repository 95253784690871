import React, { useState, useMemo} from "react";
import styled from 'styled-components'
import QueryTableModalSize from "./QueryTableModalSize";

const ClientBillingProductsTable = (props) => {

  const [show, setShow] = useState(false);
  const [valueSelected, setValueSelected] = useState({})
  const [modalType, setModalType] = useState()

    // table styles.  Taken from an example.  Investigate porting to css.  Produces warnings.

    const Wrapper = styled.section`
        padding: 0em;
        padding-top: 0rem;
        padding-bottom: 0rem;
        background: white;
        text-align: left;
        align-items: center;
        alignItems: center;
    `;
    const Styles = styled.div`
        padding: 1rem;

        .table {
          display: inline-block;
          border-spacing: 0;
          border: 1px solid #ccc;;

          .tr {
            :last-child {
              .td {
                ${'' /* border-bottom: 0; */}
                border-bottom: 1px solid #ccc;
              }
            }
          }

          .th {
            margin: 0;
            padding: .5rem;
            border-bottom: 1px solid #ccc;
            border-right: 1px solid #ccc;
            color:  #00447c;
            font-weight: 600;

            :last-child {
              border-right: 1px solid #ccc;
            }
          }


          .td {
            margin: 0;
            padding: .5rem;
            border-bottom: 1px solid #ccc;
            border-right: 1px solid #ccc;
            white-space: nowrap;
            text-overflow:ellipsis;
            overflow: hidden;

            :last-child {
              border-right: 1px solid #ccc;
            }
          }
        }
      `

  // apply fixed format to host average_rating

  function toFixedFormat (value) {
    return parseFloat(isNaN(value) ? 0 : value).toFixed(2)
  }
  function decode (value) {
    if (value === '1') {
        return "Monthly"
    }
    else if (value === '2') {
        return "Annual"
    }
    else if (value === '3') {
        return "Per Use"
    }
  }

  // simple array to map columns for table.


  const columns = useMemo(
    () => [
      {
        Header: () => (
            <span style={{display: "none"}} ></span>
        ),
        id: 'someId',
        columns: [

          {
            Header: "License",
            accessor: "license_number",
            width: 75,
            Cell: ({row}) => {
                const dataValue = row.original
                const showGameInformation = () => {
                    setValueSelected(dataValue.game_schedule_id)
                    setModalType("game contact")
                    setShow(true)
                }
            return (
                <div onClick={()=> showGameInformation()} > {dataValue.license_number} </div>
            )
            }
          },
          {
            Header: "Plan",
            accessor: "plan_name",
            width: 160,
            Cell: ({row}) => {
                const dataValue = row.original
                const showGameInformation = () => {
                    setValueSelected(dataValue.game_schedule_id)
                    setModalType("game contact")
                    setShow(true)
                }
            return (
                <div onClick={()=> showGameInformation()} > {dataValue.plan_name} </div>
            )
            }
          },
          {
            Header: "Description",
            accessor: "plan_description",
            width: 223,
            Cell: ({row}) => {
                const dataValue = row.original
                const showGameInformation = () => {
                    setValueSelected(dataValue.game_schedule_id)
                    setModalType("game contact")
                    setShow(true)
                }
            return (
                <div onClick={()=> showGameInformation()} > {dataValue.plan_description} </div>
            )
            }
          },
          {
            Header: "Effective",
            accessor: "effective_date",
            width: 120,
            Cell: ({row}) => {
                const dataValue = row.original
                const showGameInformation = () => {
                    setValueSelected(dataValue.game_schedule_id)
                    setModalType("game contact")
                    setShow(true)
                }
            return (
                <div onClick={()=> showGameInformation()} > {dataValue.effective_date} </div>
            )
            }
          },
          {
            Header: "Renewal",
            accessor: "renewal_date",
            width: 120,
            Cell: ({row}) => {
                const dataValue = row.original
                const showHostInformation = () => {
                    setValueSelected(dataValue.game_schedule_id)
                    setModalType("host contact")
                    setShow(true)
                }
            return (
                <div onClick={()=> showHostInformation()} > {dataValue.renewal_date} </div>
            )
            }
          },
          {
            Header: "Frequency",
            accessor: "billing_frequency",
            width: 96,
            Cell: ({row}) => {
                const dataValue = row.original
                const showHostInformation = () => {
                    setValueSelected(dataValue.game_schedule_id)
                    setModalType("host contact")
                    setShow(true)
                }
            return (
                <div onClick={()=> showHostInformation()} > {decode(dataValue.billing_frequency)} </div>
            )
            }
          },
          {
            Header: "Standard",
            accessor: "per_billing_charge",
            width: 90,
            Cell: ({row}) => {
                const dataValue = row.original
                const showHostInformation = () => {
                    setValueSelected(dataValue.game_schedule_id)
                    setModalType("host contact")
                    setShow(true)
                }
            return (
                <div onClick={()=> showHostInformation()} > {toFixedFormat(dataValue.per_billing_charge)} </div>
            )
            }
          },
          {
            Header: "Dsct %",
            accessor: "discount_pct",
            width: 75,
            Cell: ({row}) => {
                const dataValue = row.original
                const showHostInformation = () => {
                    setValueSelected(dataValue.game_schedule_id)
                    setModalType("host contact")
                    setShow(true)
                }
            return (
                <div onClick={()=> showHostInformation()} > {toFixedFormat(dataValue.discount_pct)} </div>
            )
            }
          },
          {
            Header: "Charge",
            accessor: "calculated_fee",
            width: 90,
            Cell: ({row}) => {
                const dataValue = row.original
                const showHostInformation = () => {
                    setValueSelected(dataValue.game_schedule_id)
                    setModalType("host contact")
                    setShow(true)
                }
            return (
                <div onClick={()=> showHostInformation()} > {toFixedFormat(dataValue.calculated_fee)} </div>
            )
            }
          },

        ]
      },
    ],
    []
  );
  return (
      <Wrapper>
        <Styles>
            <QueryTableModalSize
              columns={columns}
              data={props.data}
              caller={props.caller}
              setRefresh={props.setRefresh}
              refresh={props.refresh}
              setShow={setShow}
              show={show}
              valueSelected={valueSelected}
              modalType={modalType}
               />
        </Styles>
    </Wrapper>
  );
}

export default ClientBillingProductsTable;