import './HostSection.css';
import './MoreAboutHost.css'
import React, {createRef, useRef}  from "react"
import { Container, Row, Col, Stack} from 'react-bootstrap';
import RatingStars from "./RatingStars";
import { SocialIcon } from 'react-social-icons';
import ScheduleButton from './ScheduleButton';
import ResponsivePlayer from './ResponsivePlayer';
import ueIcon from '../assets/UElogo_whiteback.png'
import publicIcon from '../assets/public-icon-16.jpg'


const MoreAboutHost = (
    {userId, firstName, lastName,  availableToPublic, paidHostFlag, hostFee, photoLink, headline, hostMessage, resumeLink,
     averageRating, hostedGameCount, hostTimezone, emailId, phoneNumber, videoLinkIntro, videoLinkExample1,
     videoLinkExample2, videoLinkExample3, calendarLink, facebookLink, instagramLink, tiktokLink,
     linkedInLink, paymentLink}
    ) => {

        let sendMail = 'mailto:'+emailId+'?subject=Hi ' +firstName+ ' - Email from%20Utopia%20Experiences%20Host%20Booking%20Page!'

        // The following Ref utilizations are to track and control video play in stopOtherPlayer
        // in the ResponisvePlayer component.

        const playerRef = useRef('default')
        const videoIntro = createRef();
        const videoExample1 = createRef();
        const videoExample2 = createRef();
        const videoExample3 = createRef();

        const starSize = 25 //sizing parm for Rating Stars (size can't be handeled with css)

        return (
        <div>
        <Container fluid className="host-container more-about-alignment">
        <Container fluid >
        <Row className='d-flex justify-content-left'>
            <Col lg={true} >
                <br/><br/>
                <ResponsivePlayer
                    videoLink = {videoLinkIntro}
                    videoRef = {videoIntro}
                    playerRef = {playerRef}
                    />
                <br></br>
                {(videoLinkExample1) &&
                <Row className='d-flex justify-content-center'>
                {(videoLinkExample1) &&
                <Col>
                <ResponsivePlayer
                    videoLink = {videoLinkExample1}
                    videoRef = {videoExample1}
                    playerRef = {playerRef}
                    />
                </Col>
                }
                {(videoLinkExample2) &&
                <Col>
                <ResponsivePlayer
                    videoLink = {videoLinkExample2}
                    videoRef = {videoExample2}
                    playerRef = {playerRef}
                    />
                </Col>
                }
                {(videoLinkExample3) &&
                <Col>
                <ResponsivePlayer
                    videoLink = {videoLinkExample3}
                    videoRef = {videoExample3}
                    playerRef = {playerRef}
                    />
                </Col>
                }
                </Row>
                }
            </Col>
            <Col lg={true} style={{disply:'flex', justifyContent:'left'}} >
                <br/>
                <Stack direction = "horizontal" gap={2}>
                <div className="client-rating-text">Client Rating: </div>
                <div>
                        <RatingStars
                            averageRating = {averageRating}
                            starSize = {starSize}
                            />
                </div>
                </Stack>
                <div>Number of games hosted:  {hostedGameCount}</div>
                <div>Timezone(s): {hostTimezone}</div>
                <div>
                <Stack direction="horizontal" gap={2}>
                    {(availableToPublic === 'y' || availableToPublic === 'Y')
                     &&
                        <p>  Available to General Public {' '}
                        <img className="public-icon" src={publicIcon} alt={"Public Logo"}/>
                        </p>
                    }
                </Stack>
                <Stack direction="horizontal" gap={2}>
                    {(availableToPublic !== 'y' && availableToPublic !== 'Y')
                    &&
                        <p>  Exclusively available to Utopia Experiences clients {' '}
                        <img className="public-icon" src={ueIcon} alt={"UE Logo"}/>
                        </p>
                    }
                </Stack>
                </div>
                <Stack direction="horizontal" gap={2}>
                <div>Fee: ${hostFee}
                    {availableToPublic !== 'y' &&
                        <span className='small-print'>   (fee is applicable based on your subscription)</span>
                    }
                </div>
                </Stack>
                <br></br>
                <div className="host-message">{hostMessage}</div>
                <br></br>
                <div>
                <Stack direction="horizontal" gap={2}>
               {(facebookLink)
                    && <SocialIcon className="trans-button" url={facebookLink}/>}
               {(instagramLink)
                    && <SocialIcon className="trans-button" url={instagramLink}/>}
               {(tiktokLink)
                    && <SocialIcon className="trans-button" url={tiktokLink}/>}
               {(linkedInLink)
                    && <SocialIcon className="trans-button" url={linkedInLink} />}
               {(resumeLink)
                    && <SocialIcon className="trans-button" url={resumeLink} label="Resume" />}
                {(emailId)
                    &&
                    <SocialIcon className="trans-button" url={sendMail} />}
                </Stack>

                <ScheduleButton firstName = {firstName}
                            photoLink = {photoLink}
                            calendarLink={calendarLink}
                            availableToPublic={availableToPublic} />
               </div>
                </Col>
            </Row>
            </Container>
        </Container>
        </div>
    )
}

export default MoreAboutHost