// thanks to tannerlinsley / react-table / master /examples/virtualized-rows
import React, {useState} from "react";
import { useTable, useBlockLayout } from 'react-table'
import { FixedSizeList } from 'react-window'
import scrollbarWidth from '../utils/scrollbarWidth'
import ModalContainer from "./ModalContainer"


const Table = ({ columns, data, caller, setRefresh, refresh }) => {

  console.log ("Table data= ", data)

  const [valueSelected, setValueSelected] = useState({})

  // set values for call to ModalContainer
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  // Use the state and functions returned from useTable to build UI

  const defaultColumn = React.useMemo(
    () => ({
      width: 150,
    }),
    []
  )

  const scrollBarSize = React.useMemo(() => scrollbarWidth(), [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    totalColumnsWidth,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useBlockLayout
  )

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index]
      prepareRow(row)
      return (
        <div
          {...row.getRowProps({
            style,

          })}
          className="tr"
          onClick={() => {

            if (caller === "Sponsored Host Information" ) {
                setValueSelected(row.original.user_id)
            }
            else if (caller === "Timezones") {
                setValueSelected(row.original.master_timezone_id)
            }
            else if (caller === "Training Assessments") {
                setValueSelected(row.original.assessment_id)
            }
            else if (caller === "Training Videos") {
                setValueSelected(row.original.training_id)
            }
            else if (caller === "Zoom Licenses") {
                setValueSelected(row.original.zoom_account_user_id)
            }
            setShow(true)
            console.log(row.original)}
          }
        >
          {row.cells.map(cell => {
            return (
              <div {...cell.getCellProps()} className="td">
                {cell.render('Cell')}
              </div>
            )
          })}
        </div>
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [prepareRow, rows]
  )

  // Render the UI for your table
  return (

    <div {...getTableProps()} className="table">
      <ModalContainer
            show={show}
            handleClose={handleClose}
            caller={caller}
            formData={data}
            selectedValue={valueSelected}
            CRUD={"ReadUpdateDelete"}
            setRefresh={setRefresh}
            refresh={refresh}
        />

      <div>
        {headerGroups.map(headerGroup => (
          <div {...headerGroup.getHeaderGroupProps()} className="tr">
            {headerGroup.headers.map(column => (
              <div {...column.getHeaderProps()} className="th">
                {column.render('Header')}
              </div>
            ))}
          </div>
        ))}
      </div>

      <div {...getTableBodyProps()}>
        <FixedSizeList
          height={500}
          itemCount={rows.length}
          itemSize={35}
          width={totalColumnsWidth+scrollBarSize}
        >
          {RenderRow}
        </FixedSizeList>
      </div>
    </div>






  )
}



export default Table