import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Container, Row, Col, Button, Stack, Spinner} from "react-bootstrap"
import MessageModal from "./MessageModal"
import doValidations from "../utils/doValidations";
import ValidationQuery from "../utils/ValidationQuery"
import WriteData from "../utils/WriteData";
import "./Form.css"

const TimezoneFormCRUD = (props) => {

    // NOTE:  This form only allows inserts, updates and deletes by user with UE SuperAdmin privs.
    let authorized = false

    if (window.sessionStorage.getItem('roleId') === '1' ) {
        authorized = true
    }

    console.log("in TimezoneFormCrud")
    const setDataChange = props.setDataChange // descructure for useEffect
    // console.log("props.setRefresh=", props.setRefresh)
    console.log("TimezoneFormCrud formData= ", props.formData)
    console.log("TimezoneFormCRUD sectionValue = ", props.masterTimezoneId)

    const REACT_APP_ENDPOINT_TIMEZONES=process.env.REACT_APP_ENDPOINT_TIMEZONES;
    let apiPath = ''

    const [dbWorkComplete, setDbWorkComplete] = useState(true);
    const [postDelete, setPostDelete] = useState(false);  //to prevent a preLoadValues load in rerender after record deletion.

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [titleMessage, setTitleMessage] = useState(null)
    const [validationMessage, setValidationMessage] = useState(null)

    // const [deleteError, setDeleteError] = useState(false)
    const [saveWarning, setSaveWarning] = useState(false)
    // const [validationLookup, setValidationLookup] = useState(false)

    let timezoneUseCount = 0
    timezoneUseCount = ValidationQuery('/timezone_use_count', props.masterTimezoneId, "timezones")
    console.log ("*****timezoneUseCount=", timezoneUseCount)

    let preloadValues = {}

    const onSubmit = async data => {
        console.log(data);
        setDbWorkComplete(false)
        console.log ("formAction=", getValues("formAction"))
        let passValidation = true
        if (getValues("formAction") === "insert" || getValues("formAction") === "update") {
            passValidation = await doValidations("TimezoneFormCRUD", getValues("formAction"), dirtyFields, data, props.formData, setValidationMessage)
        }
        if (passValidation) {
            if (getValues("formAction") === "insert") {
                apiPath=REACT_APP_ENDPOINT_TIMEZONES + "/master_timezone_insert"
                WriteData(apiPath, data, "post", setDbWorkComplete)
            }
            else if (getValues("formAction") === "update") {
                apiPath=REACT_APP_ENDPOINT_TIMEZONES + "/master_timezone_update"
                WriteData(apiPath, data, "put", setDbWorkComplete, props.masterTimezoneId, props.refresh, props.setRefresh)
                props.handleOuterClose()
            }
            else if (getValues("formAction") === "delete") {
                apiPath=REACT_APP_ENDPOINT_TIMEZONES + "/master_timezone_update"
                WriteData(apiPath, data, "delete", setDbWorkComplete, props.masterTimezoneId,  props.refresh, props.setRefresh)
                setPostDelete(true)
                setDbWorkComplete(true)
                props.handleOuterClose()
            }
            if (dbWorkComplete) {
                console.log ("back in TimezoneFormCrud dbWorkComplete")
                reset({
                    timezoneValue: "",
                    timezoneOffset: "",
                    zoomTimezone: "",
                    dstOffset: "",
                    stOffset: ""
                    },
                    {
                        keepDefaultValue: true,
                    })
                props.setDataChange(false)
                // this is a toggle to reset state for GetData to refresh after saves.
                if (!props.refresh) {
                    props.setRefresh(dbWorkComplete)
                }
                else {
                    props.setRefresh(false)
                }

             }
        }
        else {
            console.log('validationMessage=', validationMessage)
            setTitleMessage("There's a data issue")
            setDbWorkComplete(true)
            setShow(true)
        }
    }

    // console.log("in to TimezoneFormCRUD dataChange= ", props.dataChange)

    if (props.CRUD === "ReadUpdateDelete" && !postDelete) {
         let formValues = props.formData.filter(obj => {
                return obj.master_timezone_id === props.masterTimezoneId
        })
        console.log("TimezoneFormCRUD formValue= ", formValues)
        preloadValues = {
            timezoneValue: formValues[0].timezone_value,
            timezoneOffset: formValues[0].timezone_offset,
            zoomTimezone: formValues[0].zoom_timezone,
            dstOffset: formValues[0].dst_offset,
            stOffset: formValues[0].st_offset,
            formAction: "read"  // form buttons set this value to 'update', 'insert', 'delete'
        }
    }
    else {
        preloadValues = {
            timezoneValue: "",
            timezoneOffset: "",
            zoomTimezone: "",
            dstOffset: "",
            stOffset: "",
            formAction: "read"  // form buttons set this value to 'update', 'insert', 'delete'
        }
        }

    const { register, handleSubmit, watch, getValues, setValue, reset, formState: {dirtyFields}} = useForm({
        defaultValues: preloadValues
    });

    // watch tracks user input
    watch ()

    useEffect(() => {
        const subscription = watch(() => setDataChange(true))
        return () => subscription.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch]);

    return (
    <div className="form-box">
    <Container fluid className="form-box">
        <MessageModal
            show={show}
            handleClose={handleClose}
            titleMessage={titleMessage}
            validationMessage={validationMessage}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
            {(timezoneUseCount > 0) &&
                <Container fluid>
                <Row display="table" className="align-items-center justify-content-center">
                    <Col md="auto" className="orange-text" >In Use</Col>
                </Row>
                <br/><br/>
                </Container>
            }
            <Row>
            <Col >
                <label className="label-format">Timezone Name*</label>
            </Col>
            <Col xs={9}>
                <input type="text" placeholder="Timezone name" className="input-format"
                {...register("timezoneValue", {required: true})} />
            </Col>
            </Row>
            <br/>
            <Row>
            <Col >
                <label className="label-format">Zoom Timezone*</label>
            </Col>
            <Col xs={9}>
                <input type="text" placeholder="Zoom timezone name" className="input-format"
                {...register("zoomTimezone", {required: true})} />
            </Col>
            </Row>
            <br/>
            <Row >
            <Col >
                <label className="label-format">Current Offset*</label>
            </Col>
            <Col xs={9}>
                <input type="text" placeholder="Active GMT timezone offset" className="input-format"
                {...register("timezoneOffset", {required: true})} />
            </Col>
            </Row>
            <br/>
            <Row >
            <Col >
                <label className="label-format">DST Offset*</label>
            </Col>
            <Col xs={9}>
                <input type="text" placeholder="Daylight Savings Time GMT timezone offset" className="input-format"
                {...register("dstOffset", {required: true})} />
            </Col>
            </Row>
            <br/>
            <Row >
            <Col >
                <label className="label-format">ST Offset*</label>
            </Col>
            <Col xs={9}>
                <input type="text" placeholder="Standard Time GMT timezone offset" className="input-format"
                {...register("stOffset", {required: true})} />
            </Col>
            </Row>
            <br/>


            {/* conditionally render CRUD buttons based on form mode. */}

            <Row className="justify-content-center">

                    <>
                    <Stack direction="horizontal" gap={3} className="d-flex justify-content-center">

                    {(props.CRUD === "ReadUpdateDelete" && authorized === true) &&
                    <>
                    <Button className="blue-button"
                        onClick={() => {
                            if (saveWarning === false && timezoneUseCount > 0) {
                                setTitleMessage ("You are about to update a Timezone")
                                setValidationMessage("The number of communites using this timezone is: " + timezoneUseCount + ".  "
                                + "This update will impact both community date displays and game schedule times.  Please be certain "
                                + "you want to perform this action.  Press Save again to proceed."
                                )
                                setSaveWarning(true)
                                setShow(true)
                            }
                            else {
                                setValue("formAction", "update")
                                handleSubmit(onSubmit)()
                            }
                    }}
                    >Save</Button>
                    <Button className="blue-button"
                        onClick={() => {
                            if (timezoneUseCount > 0) {
                                setTitleMessage("You cannot delete this timezone")
                                setValidationMessage("Timezones impact both community date displays and game schedule times, and all communities must have a timezone.  "
                                + "The number of communites using this timezone is: " + timezoneUseCount + ".  "
                                + "To delete this timezone you must first assign a new timezone to the communities that use this one."
                                )
                                setShow(true)
                            }
                            else {
                                setValue("formAction", "delete")
                                handleSubmit(onSubmit)()
                            }
                    }}
                    >Delete</Button>
                    </>
                    }
                    {(props.CRUD === "Add" && authorized === true) &&
                    <Button className="blue-button"
                        onClick={() => {
                            setValue("formAction", "insert")
                            handleSubmit(onSubmit)()
                        }}
                    >Save</Button>
                    }
                    </Stack>
                    </>
                    <p>* Required field</p>
            </Row>
            {(!dbWorkComplete) &&
                    <div>
                    <Spinner animation="border" role="status" style={{color: "#00447c"}}>
                    <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    </div>
            }
        </form>
    </Container>
    </div>

    )

}

export default TimezoneFormCRUD