import React, {useState, useEffect} from "react";
import {Routes, Route} from "react-router-dom";
import './App.css';
import Authenticate from "./utils/Authenticate";
import Layout from './components/Layout';
import MoreAboutHostContainer from "./components/MoreAboutHostContainer";

function App() {

  // this application is only for registered UE Corp users.  Authenticate redirects if the user is not authenticated.
  const [doAuthenticate, setDoAuthenticate] = useState(true)
  const roleId = Authenticate(doAuthenticate, setDoAuthenticate)
  useEffect(()=> {
    setDoAuthenticate(false)
  }, [])
  console.log("App roleId=", roleId)

  return (
    <>
      <Routes>
        <Route exact path="/moreabouthost"
          element = {<MoreAboutHostContainer/>}/>
        <Route path= "/"
          element = {<Layout
            roleId={roleId}
          />}
        />
      </Routes>
    </>
  );
}

export default App;
