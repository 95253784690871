import React from "react";
import { useForm } from "react-hook-form";
import { Container, Row, Col } from "react-bootstrap"
import "./Form.css"

const RatingsInfo = (props) => {

    function toFixedFormat (value) {
        return parseFloat(value).toFixed(2)
      }


    let preloadValues = {}
    // let formValues = []

    console.log("RatingsInfo formData=", props.formData)
    console.log("RatingsInfo props.keyValue=", props.keyValue)

    let formValues = props.formData.filter(obj => {
        return obj.game_schedule_id === props.keyValue
    })
    console.log("ContactInfo formValue= ", formValues)

    preloadValues = {
        game: formValues[0].game_name,
        gameDate: formValues[0].game_date + " " + formValues[0].community_time,
        communityName: formValues[0].community_name,
        name: formValues[0].rater_name,
        email: formValues[0].email_id,
        phone: formValues[0].phone_number,
        hostName: formValues[0].host_name,
        engagementRating: toFixedFormat(formValues[0].host_engagement_rating),
        compentenceRating: toFixedFormat(formValues[0].host_competence_rating),
        averageRating: toFixedFormat(formValues[0].average_rating),
        ratingComments: formValues[0].host_rating_comment,
        recommendHost: formValues[0].recommend_host
        }

    const { register} = useForm({
        defaultValues: preloadValues
    });

    return (
    <div className="form-box">
    <Container fluid className="form-box">

        <form >
        <Row>
            <Col >
                <label className="label-format">Game Host:</label>
            </Col>
            <Col xs={9}>
                <input type="text" readOnly disabled="disabled" placeholder="Host Name" className="input-format"
                {...register("hostName")} />
            </Col>
            </Row>
            <br/>
            <Row>
            <Col >
                <label className="label-format">Game:</label>
            </Col>
            <Col xs={9}>
                <input type="text" readOnly disabled="disabled" placeholder="Game Name" className="input-format"
                {...register("game")} />
            </Col>
            </Row>
            <br/>
            <Row>
            <Col >
                <label className="label-format">Game Date:</label>
            </Col>
            <Col xs={9}>
                <input type="text" readOnly disabled="disabled" placeholder="Game Date" className="input-format"
                {...register("gameDate")} />
            </Col>
            </Row>
            <br/>
            <Row>
            <Col >
                <label className="label-format">Rating Comments:</label>
            </Col>
            <Col xs={9}>
                <textarea placeholder="Rating comments"
                readOnly disabled="disabled"
                className="input-format"
                magin="normal"
                cols="30"
                rows="3"
                wrap="on"
                {...register("ratingComments", {required: true, maxLength: 192})} />
            </Col>
            </Row>
            <br/>
            <Row>
            <Col >
                <label className="label-format">Recommend:</label>
            </Col>
            <Col xs={9}>
                <input type="text" readOnly disabled="disabled" placeholder="Phone Number" className="input-format"
                {...register("recommendHost")} />
            </Col>
            </Row>
            <br/>
            <Row>
            <Col >
                <label className="label-format">Engagement:</label>
            </Col>
            <Col xs={9}>
                <input type="text" readOnly disabled="disabled" placeholder="Phone Number" className="input-format"
                {...register("engagementRating")} />
            </Col>
            </Row>
            <br/>
            <Row>
            <Col >
                <label className="label-format">Competency:</label>
            </Col>
            <Col xs={9}>
                <input type="text" readOnly disabled="disabled" placeholder="Phone Number" className="input-format"
                {...register("compentenceRating")} />
            </Col>
            </Row>
            <br/>
            <Row>
            <Col >
                <label className="label-format">Average:</label>
            </Col>
            <Col xs={9}>
                <input type="text" readOnly disabled="disabled" placeholder="Phone Number" className="input-format"
                {...register("averageRating")} />
            </Col>
            </Row>
            <br/>
            <Row>
            <Col >
                <label className="label-format">Community:</label>
            </Col>
            <Col xs={9}>
                <input type="text" readOnly disabled="disabled" placeholder="Community" className="input-format"
                {...register("communityName")} />
            </Col>
            </Row>
            <br/>
            <Row>
            <Col >
                <label className="label-format">Game Contact:</label>
            </Col>
            <Col xs={9}>
                <input type="text" readOnly disabled="disabled" placeholder="Contact Name" className="input-format"
                {...register("name")} />
            </Col>
            </Row>
            <br/>
            <Row>
            <Col >
                <label className="label-format">Email Address:</label>
            </Col>
            <Col xs={9}>
                <input type="text" readOnly disabled="disabled" placeholder="Email Address" className="input-format"
                {...register("email")} />
            </Col>
            </Row>
            <br/>
            <Row>
            <Col >
                <label className="label-format">Phone Number:</label>
            </Col>
            <Col xs={9}>
                <input type="text" readOnly disabled="disabled" placeholder="Phone Number" className="input-format"
                {...register("phone")} />
            </Col>
            </Row>
            <br/>

        </form>
    </Container>
    </div>

    )

}

export default RatingsInfo