import React, {useState} from "react";
import {Card, Button, Dropdown } from "react-bootstrap";
import ModalContainer from "./ModalContainer";
import './Sidebar.css'


// Fixed size sidbar menu for navigation

const Sidebar = (props) => {

    // Sidebar includes role based renders
    // props.RoleId = 1 SuperAdmin
    // props.RoleID = 2 UeCorpAdmin
    // props.RoleID = 5 Game Host

    console.log ("*****in Sidebar*****")
    console.log ("refresh=", props.refresh)

    //for ModalContainer calls
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [caller, setCaller] = useState();
    const [callFunction, setCallFunction] = useState();
    // let caller = ""
    let formData = {}

    // This is the place to do storage clean up on rerenders for any pages that create
    // session storage for passing values.  Contol returns here unless the user leaves
    // completely.  Return to UECC via a click on Care Community button (header.jsx)
    // will clear all session storage also.

    // ReceivablesTable:
    window.sessionStorage.removeItem("currentData")

    return (
        <>
        <Card >
        <div className="sidebar-menu-container">

        <br/>

            <>
            <Dropdown>
                {/* {(props.roleId === '1') && */}

                    <Dropdown.Toggle className="menu-button" id="dropdown-basic">
                        Accounting
                    </Dropdown.Toggle>
                {/* } */}
                <Dropdown.Menu >
                {(props.roleId === '1') &&
                <Dropdown.Item className="dropdown-menu-font"
                    onClick={() => {
                        props.setFormName("Client Billing Master")
                        // toggle to rerender main to initiaite a new query
                        if (props.refresh === false) {
                            props.setRefresh(true)
                        }
                        else {
                            props.setRefresh(false)
                        }
                        }
                        }
                    >Client Billing Master</Dropdown.Item>
                }
                {(props.roleId === '1') &&
                    <Dropdown.Item className="dropdown-menu-font"
                    onClick={() => {
                        props.setFormName("Sponsored Host Payables")
                        // toggle to rerender main to initiaite a new query
                        if (props.refresh === false) {
                            props.setRefresh(true)
                        }
                        else {
                            props.setRefresh(false)
                        }
                        }
                        }
                    >Host Payables</Dropdown.Item>
                }
                    <Dropdown.Item className="dropdown-menu-font"
                    onClick={() => {
                        props.setFormName("Host Charges Review")
                        // toggle to rerender main to initiaite a new query
                        if (props.refresh === false) {
                            props.setRefresh(true)
                        }
                        else {
                            props.setRefresh(false)
                        }
                        }
                        }
                    >Host Charges Review</Dropdown.Item>
                    <Dropdown.Item className="dropdown-menu-font" href="#/action-3">Something else</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            </>

        {(props.roleId !== '5') &&
            <>
            <Button className="menu-button"
                onClick={() => {
                    props.setFormName("Sponsored Host Information")
                    // toggle to rerender main to initiaite a new query
                    if (props.refresh === false) {
                        props.setRefresh(true)
                    }
                    else {
                        props.setRefresh(false)
                    }
                    }
                }
            >Sponsored Hosts</Button>
            <br/>
            </>
        }

            <Button className="menu-button"
                onClick={() => {
                    props.setFormName("Sponsored Host Game Schedule")
                    // toggle to rerender main to initiaite a new query
                    if (props.refresh === false) {
                        props.setRefresh(true)
                    }
                    else {
                        props.setRefresh(false)
                    }
                    }
                }
            >Sponsored Host Game Schedule</Button>
            <br/>

            <Button className="menu-button"
                onClick={() => {
                    props.setFormName("Sponsored Host Ratings")
                    // toggle to rerender main to initiaite a new query
                    if (props.refresh === false) {
                        props.setRefresh(true)
                    }
                    else {
                        props.setRefresh(false)
                    }
                    }
                }
            >Sponsored Host Ratings</Button>
            <br/>

            {(props.roleId !== '5') &&
                <>
                <Button className="menu-button"
                    onClick={() => {
                        props.setFormName("Timezones")
                        // toggle to rerender main to initiaite a new query
                        if (props.refresh === false) {
                            props.setRefresh(true)
                        }
                        else {
                            props.setRefresh(false)
                        }
                        }
                    }
                >Timezones</Button>
                <br/>
                </>
            }

            {(props.roleId === '1') &&
             <>
                <Button className="menu-button"
                    onClick={() => {
                        // props.setFormName("Timezone Updates")
                        setCaller("System Timezone Updates")
                        setCallFunction("System Admin")
                        console.log("Sidebar caller=", caller)
                        console.log("Siderbar callFunction=", callFunction)
                        setShow(true)
                        // toggle to rerender main to initiaite a new query
                        // if (props.refresh === false) {
                        //     props.setRefresh(true)
                        // }
                        // else {
                        //     props.setRefresh(false)
                        // }
                        }
                    }
                >Timezone Updates</Button>
                <br/>
            </>
            }

            {(props.roleId !== '5') &&
                <>
                 <Button className="menu-button"
                    onClick={() => {
                        props.setFormName("Training Assessments")
                        // toggle to rerender main to initiaite a new query
                        if (props.refresh === false) {
                            props.setRefresh(true)
                        }
                        else {
                            props.setRefresh(false)
                        }
                        }
                    }
                >Training Assessments</Button>
                <br/>

                <Button className="menu-button"
                    onClick={() => {
                        props.setFormName("Training Videos")
                        // toggle to rerender main to initiaite a new query
                        if (props.refresh === false) {
                            props.setRefresh(true)
                        }
                        else {
                            props.setRefresh(false)
                        }
                        }
                    }
                >Training Videos</Button>
                <br/>

                <Button className="menu-button"
                    onClick={() => {
                        props.setFormName("User Activity")
                        // toggle to rerender main to initiaite a new query
                        if (props.refresh === false) {
                            props.setRefresh(true)
                        }
                        else {
                            props.setRefresh(false)
                        }
                        }
                    }
                >User Activity</Button>
                <br/>

                <Button className="menu-button"
                    onClick={() => {
                        props.setFormName("Zoom Licenses")
                        // toggle to rerender main to initiaite a new query
                        if (props.refresh === false) {
                            props.setRefresh(true)
                        }
                        else {
                            props.setRefresh(false)
                        }
                        }
                    }
                >Zoom Licenses</Button>
            </>
            }


            {/* <Button className="menu-button" href="/nowhere">This is some very long text to test with</Button> */}
            {/* <br/>
            <Dropdown>
                <Dropdown.Toggle className="menu-button" id="dropdown-basic">
                    Accounting
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>

            <br/> */}
            {/* <h4>Reserved for future use</h4> */}

        </div>
        </Card>
        <ModalContainer
            show={show}
            handleClose={handleClose}
            caller={caller}
            formData={formData}
            CRUD={callFunction}
            setRefresh={props.setRefresh}
            refresh={props.refresh}
        />
        </>
        )
  };
  export default Sidebar