import React, { useMemo} from "react";
import styled from 'styled-components'
import Table from "./Table";


const HostTable = (props) => {

    // table styles.  Taken from an example.  Investigate porting to css.  Produces warnings.

    const Wrapper = styled.section`
        padding: 0em;
        padding-top: 0rem;
        padding-bottom: 0rem;
        background: white;
        text-align: left;
        align-items: center;
        alignItems: center;
    `;
    const Styles = styled.div`
        padding: 1rem;

        .table {
          display: inline-block;
          border-spacing: 0;
          border: 1px solid #ccc;;

          .tr {
            :last-child {
              .td {
                ${'' /* border-bottom: 0; */}
                border-bottom: 1px solid #ccc;
              }
            }
          }

          .th {
            margin: 0;
            padding: .5rem;
            border-bottom: 1px solid #ccc;
            border-right: 1px solid #ccc;
            color:  #00447c;
            font-weight: 600;

            :last-child {
              border-right: 1px solid #ccc;
            }
          }


          .td {
            margin: 0;
            padding: .5rem;
            border-bottom: 1px solid #ccc;
            border-right: 1px solid #ccc;
            white-space: nowrap;
            text-overflow:ellipsis;
            overflow: hidden;

            :last-child {
              border-right: 1px solid #ccc;
            }
          }
        }
      `

  // apply fixed format to host average_rating

  // function toFixedFormat (value) {
  //   return parseFloat(value).toFixed(2)
  // }
  function toFixedFormat (value) {
    return parseFloat(isNaN(value) ? 0 : value).toFixed(2)
  }


  // simple array to map columns for table.

  const columns = useMemo(
    () => [
      {
        Header: () => (
            <span style={{display: "none"}} ></span>
        ),
        id: 'someId',
        columns: [

          {
            Header: "First Name",
            accessor: "first_name",
          },
          {
            Header: "Last Name",
            accessor: "last_name",
          },
          {
            Header: "Email Address",
            accessor: "email_id",
            width: 300,
          },
          {
            Header: "Calendar Link",
            accessor: "calendar_link",
            width: 287,
            Cell: ({row}) => {
              const dataValue = row.original
              const showCalendarLink = () => {
                window.open(dataValue.calendar_link, "_blank")
              }
          return (
              <div onClick={()=> showCalendarLink()} > {row.original.calendar_link} </div>
            )
            }
          },
          {
            Header: "Pub",
            accessor: "display_host_flag",
            width: 55,
          },
          {
            Header: "Rating",
            accessor: "average_rating",
            width: 75,
            Cell: ({row}) => {
              const dataValue = row.original
            return (
              <div> {toFixedFormat(dataValue.average_rating)} </div>
            )
            }
          },
          {
            Header: "Games",
            accessor: "hosted_game_count",
            width: 75,
            // Cell: ({row}) => {
            //   const dataValue = row.original
            // return (
            //   <div> {toFixedFormat(dataValue.)} </div>
            // )
            // }
          },
          {
            Header: "Fee",
            accessor: "host_fee",
            width: 75,
            Cell: ({row}) => {
              const dataValue = row.original
            return (
              <div> {toFixedFormat(dataValue.host_fee)} </div>
            )
            }
          }
        ]
      },
    ],
    []
  );
  return (
      <Wrapper>
        <Styles>
            <Table
              columns={columns}
              data={props.data}
              caller={props.caller}
              setRefresh={props.setRefresh}
              refresh={props.refresh}
               />
        </Styles>
    </Wrapper>
  );
}

export default HostTable;