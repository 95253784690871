import React, { useState, useMemo} from "react";
import styled from 'styled-components'
import ReceivablesTable from "./ReceivablesTable";

const ClientBillingMasterTable = (props) => {

  const [show, setShow] = useState(false);
  const [valueSelected, setValueSelected] = useState({})
  const [modalType, setModalType] = useState("None")
  const [currentData, setCurrentData] = useState([])
  const [CRUD, setCRUD] = useState()

//   console.log("in ClientBillingMaster props.data=", props.data)

  // const [totalFees, setTotalFees] = useState(0)

  // preprocessing...

  for (let i = 0; i < props.data.length; i++)  {
    //   props.data[i]['license_change'] = "A"
      if (props.data[i].community_id === null) {
        props.data[i]['billable'] = "N"
      }
      else {
        props.data[i]['billable'] = "Y"
      }
      if (props.data[i].date_joined === null) {
        props.data[i].date_joined = props.data[i].created_at
      }
      if (props.data[i].street_address === null) {
        props.data[i].street_address = props.data[i].community_street_address
      }
      if (props.data[i].product_count === null) {
        props.data[i].product_count = 0
      }
      if (props.data[i].product_count !== props.data[i].current_product_count) {
        console.log("updating product_count.....")
        props.data[i].license_change = "*"
        props.data[i].product_count = props.data[i].current_product_count
        console.log ("license_change=", props.data[i].license_change)
      }
      if (props.data[i].export_date === null) {
        props.data[i].export_date = "N"
      }
  }

    // table styles.  Taken from an example.  Investigate porting to css.  Produces warnings.

    const Wrapper = styled.section`
        padding: 0em;
        margin-top: -1.5rem;
        padding-top: 0rem;
        padding-bottom: 0rem;
        background: white;
        text-align: left;
        align-items: center;
        alignItems: center;
    `;
    const Styles = styled.div`
        padding: 1rem;

        .table {
          display: inline-block;
          border-spacing: 0;
          border: 1px solid #ccc;;

          .tr {
            :last-child {
              .td {
                ${'' /* border-bottom: 0; */}
                border-bottom: 1px solid #ccc;
              }
            }
          }

          .th {
            margin: 0;
            padding: .5rem;
            border-bottom: 1px solid #ccc;
            border-right: 1px solid #ccc;
            color:  #00447c;
            font-weight: 600;

            :last-child {
              border-right: 1px solid #ccc;
            }
          }


          .td {
            margin: 0;
            padding: .5rem;
            border-bottom: 1px solid #ccc;
            border-right: 1px solid #ccc;
            white-space: nowrap;
            text-overflow:ellipsis;
            overflow: hidden;

            :last-child {
              border-right: 1px solid #ccc;
            }
          }
        }
      `

  // simple array to map columns for table.


  const columns = useMemo(
    () => [
      {
        Header: () => (
            <span style={{display: "none"}} ></span>
        ),
        id: 'someId',
        columns: [

          // hidden columns
          {
            Header: "Billing Contact",
            // id: "client_billing_name",
            accessor: "client_billing_name",
          },

          // visible columns
          {
            Header: "Community",
            accessor: "community_name",
            width: 389,
          },
          {
            Header: "Street Address",
            accessor: "street_address",
            width: 295,
          },
          {
            Header: "Date Joined",
            accessor: "date_joined",
            width: 120,
          },
          {
            Header: "Products",
            accessor: "product_count",
            width: 120,
          },
          {
            Header: "Bill Enabled",
            accessor: "billable",
            width: 120,
          },
          {
            Header: "Exported",
            accessor: "export_date",
            width: 120,
          },
          {
            Header: "*",
            accessor: "license_change",
            disableFilters: true,
            width: 3,
          },
        ]
      },
    ],
    []
  );
  return (
      <Wrapper>
        <Styles>
            <ReceivablesTable
              columns={columns}
              data={props.data}
              caller={props.caller}
              setRefresh={props.setRefresh}
              refresh={props.refresh}
              setShow={setShow}
              show={show}
              valueSelected={valueSelected}
              setValueSelected={setValueSelected}
              modalType={modalType}
              setModalType={setModalType}
              CRUD={CRUD}
              setCRUD={setCRUD}
              currentData={currentData}
              setCurrentData={setCurrentData}
               />
        </Styles>
    </Wrapper>
  );
}

export default ClientBillingMasterTable;