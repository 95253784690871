import React from "react"
import {Modal, Button} from "react-bootstrap"
import ContactInfo from "./ContactInfo"
import HostInfo from "./HostInfo"
import RatingsInfo from "./RatingsInfo"

const InformationModal = (props) => {


    // conditional to create full screen modal for some forms
    let fullScreen = false
    if (props.caller === "Host Information") {
        fullScreen = true
    }
    console.log ("InformationModal selectedValue =", props.selectedValue)
    // state variable and function to pass to forms to track form data changes

    let displayTitle = ''

    if ((props.caller ==="Host Charges Review"
        || props.caller === "Sponsored Host Game Schedule"
        || props.caller === "Sponsored Host Payables")
        && props.modalType === "game contact") {
        displayTitle = "Game Contact Information"
    } else if ((props.caller ==="Host Charges Review"
        || props.caller === "Sponsored Host Game Schedule"
        || props.caller === "Sponsored Host Payables"
        || props.caller === "Sponsored Host Ratings")
        && props.modalType === "host contact") {
        displayTitle = "Host Contact Information"
    }  else if (props.caller === "Sponsored Host Ratings" && props.modalType === "rating information") {
        displayTitle = "Host Rating Information"
    }

    return (
    <>

    {/* this is the 'main' modal that serves as a container for Information forms */}

    <Modal contentClassName="popup-content"
        show={props.show}
        onHide={props.handleClose}
        fullscreen={fullScreen}
        size="xl"
        centered="true"
        aria-labelledby="contained-modal-title-vcenter"

        >
        <Modal.Header closeButton closeVariant="white"></Modal.Header>
        <Modal.Title className = "modal-title" >{displayTitle}</Modal.Title>
        <br/>
        <Modal.Body>
        {((props.caller ==="Host Charges Review"
            || props.caller ==="Sponsored Host Game Schedule"
            || props.caller ==="Sponsored Host Payables")
            && props.modalType === "game contact") &&
          <ContactInfo
            keyValue={props.selectedValue}
            formData={props.formData}
            handleOuterClose={props.handleClose}
            />
        }
        {((props.caller ==="Host Charges Review"
            || props.caller ==="Sponsored Host Game Schedule"
            || props.caller ==="Sponsored Host Payables"
            || props.caller ==="Sponsored Host Ratings" )
            && props.modalType === "host contact") &&
          <HostInfo
            keyValue={props.selectedValue}
            formData={props.formData}
            handleOuterClose={props.handleClose}
            />
        }
        {(props.caller ==="Sponsored Host Ratings"
            && props.modalType === "rating information") &&
          <RatingsInfo
            keyValue={props.selectedValue}
            formData={props.formData}
            handleOuterClose={props.handleClose}
            />
        }

        </Modal.Body>
        <Modal.Footer className="modal-footer border-0 ">
            <Button className="blue-button"
                onClick={() => {
                    props.handleClose()
                }}
                >
                Close
                </Button>
        </Modal.Footer>
    </Modal>
    </>

)

}

export default InformationModal