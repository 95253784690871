import React, { useMemo} from "react";
import styled from 'styled-components'
import Table from "./Table";


const ZoomTable = (props) => {

   // table styles.  Taken from an example.  Investigate porting to css.  Produces warnings.

    const Wrapper = styled.section`
        padding: 0em;
        padding-top: 0rem;
        padding-bottom: 0rem;
        background: white;
        text-align: left;
        align-items: center;
        alignItems: center;
    `;
    const Styles = styled.div`
        padding: 1rem;

        .table {
          display: inline-block;
          border-spacing: 0;
          border: 1px solid #ccc;;

          .tr {
            :last-child {
              .td {
                border-bottom: 1px solid #ccc;
              }
            }
          }

          .th {
            margin: 0;
            padding: .5rem;
            border-bottom: 1px solid #ccc;
            border-right: 1px solid #ccc;
            color:  #00447c;
            font-weight: 600;

            :last-child {
              border-right: 1px solid #ccc;;
            }
          }


          .td {
            margin: 0;
            padding: .5rem;
            border-bottom: 1px solid #ccc;
            border-right: 1px solid #ccc;
            white-space: nowrap;
            text-overflow:ellipsis;
            overflow: hidden;

            :last-child {
              border-right: 1px solid #ccc;;
            }
          }
        }
      `

  // simple array to map columns

  const columns = useMemo(
    () => [
      {
        Header: () => (
            <span style={{display: "none"}} ></span>
        ),
        id: 'someId',
        columns: [
          {
            Header: " ",
            accessor: " ",
            width: 106,
          },
          {
            Header: "Zoom User Id",
            accessor: "zoom_user_id",
            width: 300,
          },
          {
            Header: "Zoom User Email",
            accessor: "zoom_user_email",
            width: 360,
          },
          {
            Header: "Zoom Name",
            accessor: "zoom_user_name",
            width: 300,
          },
          {
            Header: "  ",
            accessor: "  ",
            width: 101,
          },
          // {
          //   Header: "Access Key",
          //   accessor: "access_key",
          //   width: 283,
          // },
          // {
          //   Header: "Secret Key",
          //   accessor: "secrete_key",
          //   width: 283,
          //   style: { whiteSpace: 'unset' }
          // },
        ]
      },
    ],
    []
  );
  return (
      <Wrapper>
        <Styles>
            <Table
              columns={columns}
              data={props.data}
              caller={props.caller}
              setRefresh={props.setRefresh}
              refresh={props.refresh}
               />
        </Styles>
    </Wrapper>
  );
}

export default ZoomTable;