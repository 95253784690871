import React, {useState} from "react"
import Select from "react-select"
import prepListData from "../utils/prepListData"
import GetDropdownData from "../utils/GetDropdownData"

const SelectStateProvinceBar = (props) => {

    const REACT_APP_ENDPOINT_ACCOUNTING=process.env.REACT_APP_ENDPOINT_ACCOUNTING
    let apiPath = REACT_APP_ENDPOINT_ACCOUNTING + "/state_provinces"

    let newStateProvinceData = GetDropdownData(apiPath, props.refresh, props.setReadComplete, props.keyValue)

    // console.log ("StateProvinceBar newStateProvinceData=", newStateProvinceData)
    // console.log ("SeletBar data ", props.data)
    // console.log ("SelectBar caller=", props.caller)
    props.setStateProvincePicked(false)
    let selectedValues = {}
    const [selectedStateProvinceValue,setSelectedStateProvinceValue]=useState(null)

    const handleChange = e => {
        setSelectedStateProvinceValue(e.value)
        console.log("master_state_province_id selected= ", e.value)
        let dataValues = newStateProvinceData.filter(obj => {
          return obj.master_state_province_id === e.value
        })
        selectedValues = {
          country: dataValues[0].country,
          state: dataValues[0].state,
          masterStateProvinceId: dataValues[0].master_state_province_id,
        }
        console.log ("SelectStateProvinceBar selectedValues=", selectedValues)
        props.setStateProvincePicked(true)
        props.setSelectedStateProvinceValues(selectedValues)
        // props.setContactSelected(true)
    }

    // prepListData pulls label and value data for display in Select
    const options = prepListData ("SelectStateProvinceBar", newStateProvinceData)
    // console.log (options)

    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          borderBottom: '1px dotted lightblue',
          color: state.isSelected ? 'white' : 'primary25',
          padding: 20,
        }),
        control: () => ({
          // none of react-select's styles are passed to <Control />
          width: 300,
        }),
        singleValue: (provided, state) => {
          const opacity = state.isDisabled ? 0.5 : 1;
          const transition = 'opacity 300ms';
          return { ...provided, opacity, transition };
        }
      }

    return (

        <div style={{width: '300px'}}>
        <Select
            styles={customStyles}
            placeholder = "Select State Province"
            value={options.find(obj => obj.value === selectedStateProvinceValue)}
            options={options}
            onChange={handleChange}
        />
        </div>
    )
}

export default SelectStateProvinceBar