
import {useState, useEffect} from "react";
import axios from "axios";
import axiosRetry from 'axios-retry';

// Use for preloading data for validations when forms are intialized.  Because of the useState hook this
// file is not the place to do on-click validations.
//
// NOTE:
// Now expanding the use of this function for more than just validations.  Using it for general retreival
// of data for the Client Billing Module.  The fuction name should be changed at some point to reflect this
// expaneded use.  It's a general function that can be re-used for more than just validation lookups which was it
// original intent.

const ValidationQuery = (path, key, endPoint) => {

    const REACT_APP_ENDPOINT_HOST=process.env.REACT_APP_ENDPOINT_HOST;
    const REACT_APP_ENDPOINT_GAMES=process.env.REACT_APP_ENDPOINT_GAMES;
    const REACT_APP_ENDPOINT_TIMEZONES=process.env.REACT_APP_ENDPOINT_TIMEZONES;
    const REACT_APP_ENDPOINT_ACCOUNTING=process.env.REACT_APP_ENDPOINT_ACCOUNTING;

    let apiPath = ''

    if (endPoint === "hosts") {
        apiPath = REACT_APP_ENDPOINT_HOST + path
    }
    else if (endPoint === "games") {
        apiPath = REACT_APP_ENDPOINT_GAMES + path
    }
    else if (endPoint === "timezones") {
      apiPath = REACT_APP_ENDPOINT_TIMEZONES + path
    }
    else if (endPoint === "accounting") {
      apiPath = REACT_APP_ENDPOINT_ACCOUNTING + path
    }
    let config = {
      headers: {
        'Authorization':  window.sessionStorage.getItem('idToken')
      },
      params: {
         key: key
      },
    }
    // console.log ("****validationQuery****")
    // console.log('apiPath= ', apiPath)
    // console.log('validationQuery key=', key)
    const [validationData, setValidationData] = useState([]);

    axiosRetry(axios, { retries: 3 });

  // Using useEffect to call the API once mounted and get the data
    useEffect(() => {
    (async () => {
      let result = ''
      result = await axios.get(apiPath, config)
        .then((res) => {
            result = res.data
            console.log ("validation query result=", result)
            setValidationData(result)
        }
    )
    //   setValidationData(result.data);
    //   setReadComplete(true)
      console.log("Validation query executed !!!!!!!!!")
    //   return (result)
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
return (validationData)
}

export default ValidationQuery