

// main data validation function.  Does form validations against preloaded data since the last
// database refresh.

function doValidations (
    caller,
    updateEvent,
    dirtyFields,
    data,
    dbValues,
    setValidationMessage,
    numberParam1,
    numberParam2) {

    console.log ("*****in doValidation called by=", caller)
    console.log ("doValidation caller ", caller)
    console.log ("doValidationupdateEvent ", updateEvent)
    console.log ("doValidation dirtyFields ", dirtyFields)
    console.log ("doValidation data ", data)
    console.log ("doValidation dbValues ", dbValues)

    // reused internal functions

    function parseTimezoneOffset (timezoneOffset) {
        // if (timezoneOffset.length !==6 ) {
        //     return (false)
        // }
        console.log("timezoneOffset is: ", typeof(timezoneOffset))
        timezoneOffset = timezoneOffset.trim()
        // let exp = new RegExp("^((?:\+|-)?[0-9]+):([0-5]?\\d")
        let exp = new RegExp(/^(?:Z|[+-](?:2[0-3]|[01][0-9]):[0-5][0-9])$/)
        if (exp.test(timezoneOffset)) {
            return (true)
        }
        return (false)
    }


    // alert ("*****in doValidation called by ", caller)
    if (caller === "ZoomFormCRUD") {
        if (dirtyFields.zoomUserId) {
            for (let i = 0; i < dbValues.length; i++) {

                if (data.zoomUserId.toLowerCase() === dbValues[i]["zoom_user_id"].toLowerCase()) {
                    setValidationMessage("The Zoom User Id you entered, '"+data.zoomUserId+",' is already in the database.  Zoom User Ids must be unique.")
                    return (false)
                }
            }
        }
        if (dirtyFields.zoomUserEmail) {
            for (let i = 0; i < dbValues.length; i++) {

                if (data.zoomUserEmail.toLowerCase() === dbValues[i]["zoom_user_email"].toLowerCase()) {
                    setValidationMessage("The Zoom Email Id you entered, '"+data.zoomUserEmail+",' is already in the database.  Zoom Email Ids must be unique.")
                    return (false)
                }
            }
        }
    }
    else if (caller === "AssessmentFormCRUD" || caller === "TrainingFormCRUD" ) {
        if (dirtyFields.catagory || dirtyFields.name) {
            for (let i = 0; i < dbValues.length; i++) {

                if (data.category.toLowerCase() === dbValues[i]["category"].toLowerCase() &&
                    data.name.toLowerCase() === dbValues[i]["name"].toLowerCase()) {
                    setValidationMessage("The combination of Module: '"+data.category+",' and "
                    + "Name:  '"+data.name+"' is already in the database.")
                    return (false)
                }
            }
        }
    }
    else if (caller === "TimezoneFormCRUD") {
        if (dirtyFields.timezoneValue) {
            for (let i = 0; i < dbValues.length; i++) {

                if (data.timezoneValue.toLowerCase() === dbValues[i]["timezone_value"].toLowerCase()) {
                    setValidationMessage("The Timezone Name, '"+data.timezoneValue+",' is already in the database.  Timezone Names must be unique.")
                    return (false)
                }
            }
        }
        if (dirtyFields.zoomTimezone) {
            for (let i = 0; i < dbValues.length; i++) {

                if (data.zoomTimezone.toLowerCase() === dbValues[i]["zoom_timezone"].toLowerCase()) {
                    setValidationMessage("The Zoom Timezone you entered, '"+data.zoomTimezone+",' is already in the database.  Zoom Timezones must be unique.")
                    return (false)
                }
            }
        }

        let pass = true

        pass = parseTimezoneOffset(data.timezoneOffset)
        if (pass === false) {
            setValidationMessage("Timezone offset must be in the format:  +/-hh:mm and exactly 6 characters in length.")
            return (false)
        }

        pass = parseTimezoneOffset(data.dstOffset)
        if (pass === false) {
            setValidationMessage("Daylight Savings Time offset must be in the format:  +/-hh:mm and exactly 6 characters in length.")
            return (false)
        }

        pass = parseTimezoneOffset(data.stOffset)
        if (pass === false) {
            setValidationMessage("Standard time offset must be in the format:  +/-hh:mm and exactly 6 characters in length.")
            return (false)
        }

    }
    else if (caller === "ClientBillingFormCRUD") {

        // This section is for first call from ClientBillingFormCrud

        if (numberParam1 === 1) {
            if (Array.isArray( data.paymentMethod)) {
                if (data.paymentMethod.length > 1) {
                    setValidationMessage("You can only enter one payment method per client.")
                    return (false)
                }
            }
            if (Array.isArray( data.deliveryMethod)) {
                if (data.deliveryMethod.length > 1) {
                    setValidationMessage("You can only enter one delivery method per client.")
                    return (false)
                }
            }
            if (data.country === null || data.state === null) {
                setValidationMessage("You must enter a state / province and a country.")
                    return (false)
            }

            if ((dirtyFields.streeAddress || dirtyFields.city)
                && data.addressAction !== "none") {  // updates are allowed for billing addresses only
                for (let i = 0; i < dbValues.length; i++) {

                    if (data.streetAddress.toLowerCase() === dbValues[i]["street_address"].toLowerCase()
                    && data.city.toLowerCase() === dbValues[i]["city"].toLowerCase()){
                        setValidationMessage("The billing address you entered, '"+data.streetAddress+" - "+ data.city+",' is already in the database.  Billing addresses should not be duplicated.")
                        return (false)
                    }
                }
            }
        }
        // second call from ClientBillingFormCrud - Contacts
        else if (numberParam1 === 2) {
            // note below contactAction === none applies to non-billing contact records which cannot
            // be updated -- so checking is irrelevant
            if ((dirtyFields.firstName || dirtyFields.lastName)
                && data.contactAction !== "none") {  // updates are allowed for billing contacts
                for (let i = 0; i < dbValues.length; i++) {

                    if (data.firstName.toLowerCase() === dbValues[i]["first_name"].toLowerCase()
                    && data.lastName.toLowerCase() === dbValues[i]["last_name"].toLowerCase()){
                        setValidationMessage("The billing contact you entered, '"+data.firstName+" "+ data.lastName+",' is already in the database.  Billing contacts cannot be duplicated.")
                        return (false)
                    }
                    // check email here too to prevent a user from doing a sneaky (also below)
                    if (data.emailId.toLowerCase() === dbValues[i]["email_id"].toLowerCase()
                        && data.userId !== dbValues[i]["user_id"]) {
                        setValidationMessage("The billing contact Email Id entered, '"+data.emailId+",' is already in the database.  Billing contact Email Ids must be unique.")
                        return (false)
                    }
                }
            }
            if (dirtyFields.emailId
                && data.contactAction !== "none") {
                for (let i = 0; i < dbValues.length; i++) {

                    if (data.emailId.toLowerCase() === dbValues[i]["email_id"].toLowerCase()
                        && data.userId !== dbValues[i]["user_id"]) {
                        setValidationMessage("The billing contact Email Id you entered, '"+data.emailId+",' is already in the database.  Billing contact Email Ids must be unique.")
                        return (false)
                    }
                }
            }
        }

    }
    else if (caller === "HostFormCRUD") {
        if (data.userId === null || data.userId === 0) {
            setValidationMessage("Apologies.  You can only add Hosts who are in the User table.  You must first enter the host to the User table through Community Care.  Then select them from the drop down at the top of this page.")
            return (false)
        }
        if (dirtyFields.emailId) {
            for (let i = 0; i < dbValues.length; i++) {

                if (data.emailId === dbValues[i]["email_id"]) {
                    setValidationMessage("The Email Address you entered, '"+data.emailId+",' is already in the database.  Host Email Addresses must be unique.")
                    return (false)
                }
            }
        }
        if (data.emailId === null) {
            setValidationMessage("An email address is required for all Hosts.  You must add an email address via Community Care to the User table before you can save this Host.")
            return (false)
        }
        if (updateEvent === "delete") {
            // check to see if host marked for deletion has other roles in addition to the Host role.  If yes, then
            // throw validation error message.
            if (numberParam1 > 0) {
                setValidationMessage("This Host has one or more roles in the database in addition to that of the Host role.  To delete this Host, you must first delete his/her additional roles through Community Care.")
                return (false)
            }
        }
        // remove all white space from empty strings and set to null
        if (dirtyFields.videoExample1) {
            if(!data.videoExample1?.trim()){
                data.videoExample1 = null
             }
        }
        if (dirtyFields.videoExample2) {
            if(!data.videoExample12?.trim()){
                data.videoExample2 = null
             }
        }
        if (dirtyFields.videoExample3) {
            if(!data.videoExample3?.trim()){
                data.videoExample3 = null
             }
        }
        if (dirtyFields.facebookLink) {
            if(!data.facebookLink?.trim()){
                data.facebookLink = null
             }
        }
        if (dirtyFields.instagramLink) {
            if(!data.instagramLink?.trim()){
                data.instagramLink = null
             }
        }
        if (dirtyFields.twitterLink) {
            if(!data.twitterLink?.trim()){
                data.instagramLink = null
             }
        }
        if (dirtyFields.tiktokLink) {
            if(!data.tiktokLink?.trim()){
                data.tiktokLink = null
             }
        }
        if (dirtyFields.linkedinLink) {
            if(!data.linkedinLink?.trim()){
                data.linkedinLink = null
             }
        }
        if (dirtyFields.resumeLink) {
            if(!data.resumeLink?.trim()){
                data.resumeLink = null
             }
        }
    }
    return (true)
}

export default doValidations