
import React from "react"

import {Button } from 'react-bootstrap';


// Ported from UE Host app repository.  Removed all functionality except for the button click.
// Used for Host Previews

const ScheduleButton = ({firstName, calendarLink}) => {

    return (
    <div >

            <Button className="blue-button"
                onClick={() => {
                    window.open(calendarLink)
                }}
                >Schedule {firstName} Now</Button>

    </div>
    )
}


export default ScheduleButton