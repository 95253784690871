import React, {useState} from "react"
import Select from "react-select"
import prepListData from "../utils/prepListData"
import GetDropdownData from "../utils/GetDropdownData"

const SelectContactBar = (props) => {

    const REACT_APP_ENDPOINT_ACCOUNTING=process.env.REACT_APP_ENDPOINT_ACCOUNTING
    let apiPath = REACT_APP_ENDPOINT_ACCOUNTING + "/all_contacts"

    let newContactData = GetDropdownData(apiPath, props.refresh, props.setReadComplete, props.keyValue)
    props.setContactsData(newContactData)
    // console.log ("SelectContactBar keyValue= ", props.keyValue)
    // console.log ("SelectContactBar newContactData=", newContactData)
    // console.log ("SelectContactBar contactsData=", props.contactsData)

    props.setContactPicked(false)
    let selectedValues = {}
    const [selectedContactValue,setSelectedContactValue]=useState(null)

    const handleChange = e => {
        setSelectedContactValue(e.value)
        console.log("host_id selected= ", e.value)
        let dataValues = newContactData.filter(obj => {
          return obj.user_id === e.value
        })
        selectedValues = {
          userId: dataValues[0].user_id,
          firstName: dataValues[0].first_name,
          lastName: dataValues[0].last_name,
          emailId: dataValues[0].email_id,
          phoneNumber: dataValues[0].phone_number,
          roleId: dataValues[0].role_id,
          userStatus: dataValues[0].status
        }
        console.log ("SelectContactBar selectedValues=", selectedValues)
        props.setContactPicked(true)
        props.setSelectedContactValues(selectedValues)
        // props.setContactSelected(true)
    }

    // prepListData pulls label and value data for display in Select
    const options = prepListData ("SelectContactBar", newContactData)
    // console.log (options)

    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          borderBottom: '1px dotted lightblue',
          color: state.isSelected ? 'white' : 'primary25',
          padding: 20,
        }),
        control: () => ({
          // none of react-select's styles are passed to <Control />
          width: 300,
        }),
        singleValue: (provided, state) => {
          const opacity = state.isDisabled ? 0.5 : 1;
          const transition = 'opacity 300ms';
          return { ...provided, opacity, transition };
        }
      }

    return (

        <div style={{width: '300px'}}>
        <Select
            styles={customStyles}
            placeholder = "Select Contact"
            value={options.find(obj => obj.value === selectedContactValue)}
            options={options}
            onChange={handleChange}
        />
        </div>
    )
}

export default SelectContactBar