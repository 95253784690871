import {useState, useEffect} from "react";
import axios from "axios";

// User authentication.  They must be logged into UECC for access.
// This function redirects to UE Admin login page on failure to validate.
// If the user has a valid access token found in the user table, and the user is a member
// of UE Corp, (community_user value 1) it writes the user_id to session storage (if found)
// for use in other components.

const Authenticate = (doAuthenticate, setDoAuthenticate) => {

  function getCookie(name) {
    // Look for the cookie from the UE Game Platform
    // Split cookie string and get all individual name=value pairs in an array
    let cookieArr = document.cookie.split(";");

    // Loop through the array elements
    for(let i = 0; i < cookieArr.length; i++) {
        let cookiePair = cookieArr[i].split("=");

        /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
        if(name === cookiePair[0].trim()) {
            // Decode the cookie value and return
            return decodeURIComponent(cookiePair[1]);
        }
    }
    // Return null if not found
    return null;
  }
    // accessToken is used for user authentication and access
    const accessToken = getCookie('accesstoken')

    // idToken is used for Api Gateway access
    const idToken = getCookie('idtoken')
    // console.log ("Authenticate:  idtoken=", idToken)


    const REACT_APP_AUTHENTICATION_REDIRECT =process.env.REACT_APP_AUTHENTICATION_REDIRECT

    if (!accessToken) {
      window.open(REACT_APP_AUTHENTICATION_REDIRECT, "_self")
    }
    else {
      window.sessionStorage.setItem('accessToken', accessToken)
    }
    if (idToken) {
      window.sessionStorage.setItem('idToken', idToken)
    }

    const REACT_APP_ENDPOINT_USER=process.env.REACT_APP_ENDPOINT_USER;
    const apiPath=REACT_APP_ENDPOINT_USER + '/authenticate_user'
    let config = {
      headers: {
        'Authorization':  window.sessionStorage.getItem('idToken')
      },
      params: {
        access_token: accessToken
      },
    }

    const [data, setData] = useState([]);

    useEffect(() => {
      (async () => {
        const result = await axios.get( apiPath, config)
            // apiPath,
            // {params: { access_token: accessToken}})
        setData(result.data);
        if (result.data[0] === undefined) {
          window.open(REACT_APP_AUTHENTICATION_REDIRECT, "_self")
        }
        else {
          window.sessionStorage.setItem('userId', result.data[0].user_id)
          window.sessionStorage.setItem('roleId', result.data[0].role_id)
        }
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [doAuthenticate]);


  // window.sessionStorage.setItem('userId', 22)
  // window.sessionStorage.setItem('roleId', 1)
  return (window.sessionStorage.getItem('roleId'))
}

export default Authenticate