
// creates list data for SelectBar based on the calling form which provides
// context to SelectBar

function prepListData (caller, data) {
    let selectList = []
    let label = ''
    let value = ''
    let obj = {}

    if (caller === "SelectAddressBar") {
        for (let i = 0; i < data.length; i++ ) {
            label = data[i]["street_address"] + " - " + data[i]["city"]
            value = data[i]["address_id"]
            obj = {value: value, label: label}
            selectList.push(obj)
        }
    }
    if (caller === "SelectContactBar"
        || caller === "Sponsored Host Information") {
        for (let i = 0; i < data.length; i++ ) {
            label = data[i]["first_name"] + " " + data[i]["last_name"]
            value = data[i]["user_id"]
            obj = {value: value, label: label}
            selectList.push(obj)
        }
    }
    if (caller === "SelectStateProvinceBar") {
    for (let i = 0; i < data.length; i++ ) {
        label = data[i]["name"] + " - " + data[i]["country"]
        value = data[i]["master_state_province_id"]
        obj = {value: value, label: label}
        selectList.push(obj)
    }
}
    if (caller === "Timezones") {
        for (let i = 0; i < data.length; i++ ) {
            label = data[i]["timezone_value"]
            value = data[i]["master_timezone_id"]
            obj = {value: value, label: label}
            selectList.push(obj)
        }
    }
    if (caller === "Training Assessments") {
        for (let i = 0; i < data.length; i++ ) {
            label = data[i]["name"]
            value = data[i]["assessment_id"]
            obj = {value: value, label: label}
            selectList.push(obj)
        }
    }
    if (caller === "Training Videos") {
        for (let i = 0; i < data.length; i++ ) {
            label = data[i]["name"]
            value = data[i]["training_id"]
            obj = {value: value, label: label}
            selectList.push(obj)
        }
    }
    if (caller === "Zoom Licenses") {
        for (let i = 0; i < data.length; i++ ) {
            label = data[i]["zoom_user_id"]
            value = data[i]["zoom_account_user_id"]
            obj = {value: value, label: label}
            selectList.push(obj)
        }
    }
    return selectList
}

export default prepListData