import React, {useState} from "react"
import Select from "react-select"
import prepListData from "../utils/prepListData"
import GetDropdownData from "../utils/GetDropdownData"

const SelectNewHostBar = (props) => {

    const REACT_APP_ENDPOINT_HOST=process.env.REACT_APP_ENDPOINT_HOST
    let apiPath = REACT_APP_ENDPOINT_HOST + "/new_hosts"

    let newHostData = GetDropdownData(apiPath, props.refresh, props.setReadComplete)
    console.log ("SeletcNewHostBar newHostData=", newHostData)

    // console.log ("SeletBar data ", props.data)
    // console.log ("SelectBar caller=", props.caller)
    props.setHostPicked(false)
    let selectedValues = {}
    const [selectedHostValue,setSelectedHostValue]=useState(null)

    const handleChange = e => {
        setSelectedHostValue(e.value)
        console.log("host_id selected= ", e.value)
        let dataValues = newHostData.filter(obj => {
          return obj.user_id === e.value
        })
        selectedValues = {
          userId: dataValues[0].user_id,
          firstName: dataValues[0].first_name,
          lastName: dataValues[0].last_name,
          emailId: dataValues[0].email_id,
          phoneNumber: dataValues[0].phone_number
        }
        console.log ("SelectNewHostBar selectedValues=", selectedValues)
        props.setHostPicked(true)
        props.setSelectedHostValues(selectedValues)
        // props.setNewHostSelected(true)
    }

    // prepListData pulls label and value data for display in Select
    const options = prepListData ("Sponsored Host Information", newHostData)
    // console.log (options)

    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          borderBottom: '1px dotted lightblue',
          color: state.isSelected ? 'white' : 'primary25',
          padding: 20,
        }),
        control: () => ({
          // none of react-select's styles are passed to <Control />
          width: 300,
        }),
        singleValue: (provided, state) => {
          const opacity = state.isDisabled ? 0.5 : 1;
          const transition = 'opacity 300ms';
          return { ...provided, opacity, transition };
        }
      }

    return (

        <>
        <Select
            styles={customStyles}
            placeholder = "Select New Host"
            value={options.find(obj => obj.value === selectedHostValue)}
            options={options}
            onChange={handleChange}
        />
        </>
    )
}

export default SelectNewHostBar