
import React, {useState} from "react"
import Select from "react-select"
import prepListData from "../utils/prepListData"
import ModalContainer from "./ModalContainer"


//generic SelectBar used with CRUD forms.  It calls CRUD forms for display in modal.

const SelectBar = (props) => {

    console.log ("SelectBar formData= ", props.formData)
    const [selectedValue,setSelectedValue]=useState(null)

    // set values for call to ModalContainer
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    // console.log ("SeletBar data ", props.data)
    // console.log ("SelectBar caller=", props.caller)

    const handleChange = e => {
        setShow(true)
        setSelectedValue(e.value)
    }

    // prepListData pulls label and value data for display in Select
    const options = prepListData (props.caller, props.formData)
    // console.log (options)

    return (

        <div>
        <ModalContainer
            show={show}
            handleClose={handleClose}
            caller={props.caller}
            formData={props.formData}
            selectedValue={selectedValue}
            CRUD={"ReadUpdateDelete"}
            setRefresh={props.setRefresh}
            refresh={props.refresh}
        />
        <Select
            placeholder = "Enter search value to View / Edit"
            value={options.find(obj => obj.value === selectedValue)}
            options={options}
            onChange={handleChange}
        />
       </div>
    )
}

export default SelectBar