import React from "react"
import {Modal, Button} from "react-bootstrap"
import "./ModalContainer.css"

const MessageModal = (props) => {

    return (

    <Modal contentClassName="popup-content"
        show={props.show}
        onHide={props.handleClose}
        size="lg"
        centered="true"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        >
        <Modal.Header closeButton className="yellow-header"></Modal.Header>
        <Modal.Title className = "modal-title" >{props.titleMessage}</Modal.Title>
        <Modal.Body className = "modal-text"
        >
        {props.validationMessage}
        </Modal.Body>
        <Modal.Footer style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Button className="blue-button"
            onClick={() => {
                props.handleClose()
                }}
            >
            Close
        </Button>
        </Modal.Footer>
    </Modal>
    )
}

export default MessageModal