import axios from "axios";

// For use when sigleton validation queries are needed for form on-click validations

async function doValidationLookup (
    path, key, endPoint, setValidationLookup, setShow, setTitleMessage, setValidationMessage) {
    console.log ("****dovalidationLooku****")
    const REACT_APP_ENDPOINT_ZOOM=process.env.REACT_APP_ENDPOINT_ZOOM;
    let apiPath = ''
    let config = {
        headers: {
          'Authorization':  window.sessionStorage.getItem('idToken')
        },
        params: {
           key: key
        },
      }
    if (endPoint === "zoom") {
        apiPath = REACT_APP_ENDPOINT_ZOOM + path
        console.log('apiPath= ', apiPath)
        let response = await axios.get(apiPath, config)
        .then(response => {
            console.log("repsonse=", response)
            if (response.data.email === key) {
                setValidationLookup(true)
                setTitleMessage("The email entered is linked to a valid Zoom license")
                setValidationMessage("Please press Save if you wish to update the database")
                setShow(true)
                console.log ("record found")
                return
            }
            else {
                setTitleMessage ("There is a data issue")
                setValidationMessage("The email address you entered, "
                    +  key
                    + ", is not linked to a valid Zoom license.  In order to enter this record you must first "
                    + "set up a license with this email address via the Zoom administration web page."
                 )
                console.log ("record not found")
                setValidationLookup(false)
                return
            }
        }).catch(error => {
            setTitleMessage ("System Error")
            setValidationMessage("There is an error calling the Zoom API.  Please contact Support")
            setShow(true)
            console.log("error response=", error.response)
        });
        setShow(true)
        return response
    }



  // Using useEffect to call the API once mounted and get the data

    // let result = false

    console.log ("exiting doValidationLookup")
}

export default doValidationLookup