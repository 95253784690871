import {useState, useEffect} from "react";
import axios from "axios";
import axiosRetry from 'axios-retry';

// generic utility used by all main forms

const GetData = (apiPath, refresh, setGetDataComplete, param, param2) => {

    console.log ("in GetData")
    console.log('apiPath= ', apiPath)
    console.log ('refresh= ', refresh)
    console.log ('param=', param)
    console.log ('param2=', param2)

    const [data, setData] = useState([]);
    let config = {
      headers: {
        'Authorization':  window.sessionStorage.getItem('idToken')
      },
      params: {
         param: param,
         param2: param2
      },
    }

    axiosRetry(axios, { retries: 3 });

  // Using useEffect to call the API once mounted and get the data
    useEffect(() => {
    (async () => {
      const result = await axios.get(apiPath, config)
      setData(result.data);
      setGetDataComplete(true)
      console.log("GetData query executed!!!!!!!!!")
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  return (data)
}

export default GetData