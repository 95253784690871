import React, {useState} from "react"
import Select from "react-select"
import prepListData from "../utils/prepListData"
import GetDropdownData from "../utils/GetDropdownData"

const SelectAddressBar = (props) => {

    const REACT_APP_ENDPOINT_ACCOUNTING=process.env.REACT_APP_ENDPOINT_ACCOUNTING
    let apiPath = REACT_APP_ENDPOINT_ACCOUNTING + "/all_organization_addresses"

    let newAddressData = GetDropdownData(apiPath, props.refresh, props.setReadComplete, props.keyValue)
    props.setAddressData(newAddressData)
    console.log ("SeletcNewAddressBar newAddressData=", newAddressData)

    // console.log ("SeletBar data ", props.data)
    // console.log ("SelectBar caller=", props.caller)
    props.setAddressPicked(false)
    let selectedValues = {}
    const [selectedAddressValue,setSelectedAddressValue]=useState(null)

    const handleChange = e => {
        setSelectedAddressValue(e.value)
        console.log("address_id selected= ", e.value)
        let dataValues = newAddressData.filter(obj => {
          return obj.address_id === e.value
        })
        selectedValues = {
          addressId: dataValues[0].address_id,
          streetAddress: dataValues[0].street_address,
          city: dataValues[0].city,
          state: dataValues[0].state,
          zipPostal: dataValues[0].zip_postal,
          country: dataValues[0].country,
          county:  dataValues[0].county,
          addressStatus: dataValues[0].status
        }
        console.log ("SelectAddressBar selectedValues=", selectedValues)
        props.setAddressPicked(true)
        props.setSelectedAddressValues(selectedValues)
        // props.setNewAddressSelected(true)
    }

    // prepListData pulls label and value data for display in Select
    const options = prepListData ("SelectAddressBar", newAddressData)
    // console.log (options)

    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          borderBottom: '1px dotted lightblue',
          color: state.isSelected ? 'white' : 'primary25',
          padding: 20,
        }),
        control: () => ({
          // none of react-select's styles are passed to <Control />
          width: 300,
        }),
        singleValue: (provided, state) => {
          const opacity = state.isDisabled ? 0.5 : 1;
          const transition = 'opacity 300ms';
          return { ...provided, opacity, transition };
        }
      }

    return (

        <>
        <Select
            styles={customStyles}
            placeholder = "Select Address"
            value={options.find(obj => obj.value === selectedAddressValue)}
            options={options}
            onChange={handleChange}
        />
        </>
    )
}

export default SelectAddressBar