import "./ResponsivePlayer.css"
import React from "react"
import ReactPlayer from "react-player/lazy"


// Copied in (and modified) from the UE Host app repo to allow users preview new Hosts.

function stopOtherPlayer (currentPlayer, playerRef) {

    // if another video player is active, shut it off to allow the currentPlayer (clicked)
    // to have air time. And record the current player.  The ref values are set in the
    // MoreAboutHost component

    if ( playerRef.current !== currentPlayer.current && playerRef.current !== 'default') {
        playerRef.current.getInternalPlayer().pauseVideo()
    }
    playerRef.current = currentPlayer.current

    return playerRef
}

const ResponsivePlayer = ({videoLink, videoRef, playerRef, trackingData}) => {

      return (
        <div className='player-wrapper'>
          <ReactPlayer
                className="react-player"
                url={videoLink}
                ref={videoRef}
                onPlay={() => {
                    stopOtherPlayer(videoRef, playerRef)
                }}
                width='100%'
                height='100%'
                controls={true}
                 />
        </div>
      )

  }

  export default ResponsivePlayer