
import React from "react";
import "./RatingStars.css";
import ReactStars from "react-rating-stars-component";

function ratingInput (averageRating, starSize){

    const input = {
        size: starSize,
        value: averageRating,
        isHalf: true,
        edit: false,
        activeColor: "#ffce00",
        // color: "#D3D3D3"
        color: "#EDEBEA"
    }

    return (input)
};

const RatingStars = ({averageRating, starSize}) => {

    let input = ratingInput (averageRating, starSize)

    return (
    <div className="RatingStars">
        <ReactStars {...input} />
    </div>
    )
}


export default RatingStars