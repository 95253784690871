import React, { useState, useMemo} from "react";
import styled from 'styled-components'
import QueryTable from "./QueryTable";


const UserActivityTable = (props) => {

  const [show, setShow] = useState(false);
  const [valueSelected, setValueSelected] = useState({})
  const [modalType, setModalType] = useState()

    // table styles.  Taken from an example.  Investigate porting to css.  Produces warnings.

    const Wrapper = styled.section`
        padding: 0em;
        padding-top: 0rem;
        padding-bottom: 0rem;
        background: white;
        text-align: left;
        align-items: center;
        alignItems: center;
    `;
    const Styles = styled.div`
        padding: 1rem;

        .table {
          display: inline-block;
          border-spacing: 0;
          border: 1px solid #ccc;;

          .tr {
            :last-child {
              .td {
                ${'' /* border-bottom: 0; */}
                border-bottom: 1px solid #ccc;
              }
            }
          }

          .th {
            margin: 0;
            padding: .5rem;
            border-bottom: 1px solid #ccc;
            border-right: 1px solid #ccc;
            color:  #00447c;
            font-weight: 600;

            :last-child {
              border-right: 1px solid #ccc;
            }
          }


          .td {
            margin: 0;
            padding: .5rem;
            border-bottom: 1px solid #ccc;
            border-right: 1px solid #ccc;
            white-space: nowrap;
            text-overflow:ellipsis;
            overflow: hidden;

            :last-child {
              border-right: 1px solid #ccc;
            }
          }
        }
      `


  // simple array to map columns for table.


  const columns = useMemo(
    () => [
      {
        Header: () => (
            <span style={{display: "none"}} ></span>
        ),
        id: 'someId',
        columns: [

         {
            Header: "User",
            accessor: "user_name",
            width: 200,
            Cell: ({row}) => {
                const dataValue = row.original
                const showHostInformation = () => {
                    setValueSelected(dataValue.game_schedule_id)
                    setModalType("host contact")
                    setShow(true)
                    }
                return (
                    <div onClick={()=> showHostInformation()} > {dataValue.user_name} </div>
                )
                }
          },
          {
            Header: "Community",
            accessor: "community_name",
            width: 300,
            Cell: ({row}) => {
                const dataValue = row.original
                const showGameInformation = () => {
                    setValueSelected(dataValue.game_schedule_id)
                    setModalType("game contact")
                    setShow(true)
                }
            return (
                <div onClick={()=> showGameInformation()} > {dataValue.community_name} </div>
            )
            }
          },
          {
            Header: "Date",
            width: 120,
            accessor: "access_date",
            Cell: ({row}) => {
                const dataValue = row.original
                const showGameInformation = () => {
                    setValueSelected(dataValue.game_schedule_id)
                    setModalType("game contact")
                    setShow(true)
                }
            return (
                <div onClick={()=> showGameInformation()} > {dataValue.access_date} </div>
            )
            }
          },
          {
            Header: "Time",
            width: 106,
            accessor: "access_time",
            Cell: ({row}) => {
                const dataValue = row.original
                const showGameInformation = () => {
                    setValueSelected(dataValue.game_schedule_id)
                    setModalType("game contact")
                    setShow(true)
                }
            return (
                <div onClick={()=> showGameInformation()} > {dataValue.access_time} </div>
            )
            }
          },
          {
            Header: "Email",
            accessor: "email_id",
            width: 316,
            Cell: ({row}) => {
                const dataValue = row.original
                const showGameInformation = () => {
                    setValueSelected(dataValue.game_schedule_id)
                    setModalType("game contact")
                    setShow(true)
                }
            return (
                <div onClick={()=> showGameInformation()} > {dataValue.email_id} </div>
            )
            }
          },

          {
            Header: "Phone",
            accessor: "phone_number",
            width: 125,
            Cell: ({row}) => {
                const dataValue = row.original
                const showHostInformation = () => {
                    setValueSelected(dataValue.game_schedule_id)
                    setModalType("host contact")
                    setShow(true)
                }
            return (
                <div onClick={()=> showHostInformation()} > {dataValue.phone_number} </div>
            )
            }
          },
        ]
      },
    ],
    []
  );
  return (
      <Wrapper>
        <Styles>
            <QueryTable
              columns={columns}
              data={props.data}
              caller={props.caller}
              setRefresh={props.setRefresh}
              refresh={props.refresh}
              setShow={setShow}
              show={show}
              valueSelected={valueSelected}
              modalType={modalType}
               />
        </Styles>
    </Wrapper>
  );
}

export default UserActivityTable;