import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Container, Row, Button, Stack, Spinner} from "react-bootstrap"
import MessageModal from "./MessageModal"
import WriteData from "../utils/WriteData";
import "./Form.css"


const TimezoneUpdateForm = (props) => {

    // const setDataChange = props.setDataChange // descructure for useEffect
    // console.log("props.setRefresh=", props.setRefresh)

    const REACT_APP_ENDPOINT_TIMEZONES=process.env.REACT_APP_ENDPOINT_TIMEZONES;
    let apiPath = ''

    const [dbWorkComplete, setDbWorkComplete] = useState(true);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [titleMessage, setTitleMessage] = useState(null)
    const [validationMessage, setValidationMessage] = useState(null)

    const [dstWarning, setDstWarning] = useState(false)
    const [stWarning, setStWarning] = useState(false)

    const keyValue = '' //set for WriteData --a placeholder in this case

    const onSubmit = async data => {
        console.log(data);
        setDbWorkComplete(false)
        console.log ("formAction=", getValues("formAction"))

        if (getValues("formAction") === "dstUpdate") {
                apiPath=REACT_APP_ENDPOINT_TIMEZONES + "/daylight_savings_time_update"
                WriteData(apiPath, data, "put", setDbWorkComplete, keyValue, props.refresh, props.setRefresh)
                props.handleOuterClose()
            }
        else if (getValues("formAction") === "stUpdate") {
                apiPath=REACT_APP_ENDPOINT_TIMEZONES + "/standard_time_update"
                WriteData(apiPath, data, "put", setDbWorkComplete, keyValue, props.refresh, props.setRefresh)
                props.handleOuterClose()
            }

        if (dbWorkComplete) {
                console.log ("back in TimezoneUpdateForm dbWorkComplete")
                props.setDataChange(false)
                // this is a toggle to reset state for GetData to refresh after saves.
                if (props.refresh === false) {
                    props.setRefresh(dbWorkComplete)
                }
                else {
                    props.setRefresh(false)
                }
        }
    }

    const { handleSubmit, getValues, setValue, } = useForm();

    return (
    <div className="form-box">
    <Container fluid className="form-box">
        <MessageModal
            show={show}
            handleClose={handleClose}
            titleMessage={titleMessage}
            validationMessage={validationMessage}
        />

        <form onSubmit={handleSubmit(onSubmit)}>

        <div className="d-flex justify-content-center">
            <h4>Functions called from this form will update all system timezones</h4>
        </div>

            <br/><br/>

            <Row className="justify-content-center">

                <>
                <Stack direction="horizontal" gap={3} className="d-flex justify-content-center">

                    <Button className="blue-button"
                        onClick={() => {
                            if (dstWarning === false) {
                                setTitleMessage ("You are about to set system timezones to Daylight Savings Time")
                                setValidationMessage("Please be certain you want to do this.  Press the button again to proceed.")
                                setDstWarning(true)
                                setShow(true)
                            }
                            else {
                                setValue("formAction", "dstUpdate")
                                handleSubmit(onSubmit)()
                            }
                    }}
                    >Set Timezones to Daylight Savings Time</Button>
                    <Button className="blue-button"
                        onClick={() => {
                            if (stWarning === false) {
                                setTitleMessage("You are about to set system timezones to Standard Time")
                                setValidationMessage("Please be certain you want to do this.  Press the button again to proceed.")
                                setStWarning(true)
                                setShow(true)
                            }
                            else {
                                setValue("formAction", "stUpdate")
                                handleSubmit(onSubmit)()
                            }
                    }}
                    >Set Timezones to Standard Time</Button>

                </Stack>
                </>

            </Row>
            {(!dbWorkComplete) &&
                    <div>
                    <Spinner animation="border" role="status" style={{color: "#00447c"}}>
                    <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    </div>
            }
        </form>
    </Container>
    </div>

    )

}

export default TimezoneUpdateForm