import React, { useState,} from "react";
import {Container, Button, Spinner} from "react-bootstrap";
import GetData from "../utils/GetData"
import ClientBillingMasterTable from "./ClientBillingMasterTable";
import GameScheduleTable from "./GameScheduleTable";
import HostRatingTable from "./HostRatingTable";
import UserActivityTable from "./UserActivityTable";
import HostPayablesTable from "./HostPayablesTable";
import HostAuditTable from "./HostAuditTable";
import "./Form.css"

// Runs queries against the database.  Calls the SelectBar, and ModalContainer for popup to display
// CRUD forms, which do the data entry work.  Also displays a table of query results for selection
// which will also take the user to ModalContainer and CRUD forms.

const QueryContainer = (props) => {

  console.log ("in QueryContainer")
  let requestPath = ''
  let caller = ''
  let addButtonText = ''
  let roleId = window.sessionStorage.getItem('roleId')
  let userId = window.sessionStorage.getItem('userId')

  // roleID 5 is for UE Hosts.  In certain cases, we only want UE hosts to be able see their own
  // records through UEBA rather than all records.  This is enforced on the server side and enabled
  // when userID is passed to GetData.  In this context GetData passes to the server via param2.

  if (roleId !== "5") {
    userId = null;
  }

  //Path definitions

  const REACT_APP_ENDPOINT_ACCOUNTING=process.env.REACT_APP_ENDPOINT_ACCOUNTING;
  const REACT_APP_ENDPOINT_GAMES=process.env.REACT_APP_ENDPOINT_GAMES;
  const REACT_APP_ENDPOINT_HOST=process.env.REACT_APP_ENDPOINT_HOST;
  const REACT_APP_ENDPOINT_USER=process.env.REACT_APP_ENDPOINT_USER;

  const [getDataComplete, setGetDataComplete] = useState(false);

  function getTimezoneOffset() {
    function z(n){return (n<10? '0' : '') + n}
    var offset = new Date().getTimezoneOffset();
    var sign = offset < 0? '+' : '+';  // flip for mysql
    offset = Math.abs(offset);
    return sign + z(offset/60 | 0) + ":" + z(offset%60);
  }

  let timezoneOffset = getTimezoneOffset()
  console.log ("*************** UTC Offset=", timezoneOffset)

  if (props.formName === "Client Billing Master") {
    requestPath = REACT_APP_ENDPOINT_ACCOUNTING + "/client_billing_master"
  }
  if (props.formName === "Sponsored Host Game Schedule") {
    requestPath = REACT_APP_ENDPOINT_GAMES + "/game_schedule"
  }
  if (props.formName === "Sponsored Host Payables"  || props.formName === "Host Charges Review") {
    requestPath = REACT_APP_ENDPOINT_ACCOUNTING + "/game_schedule_payables"
  }
  if (props.formName === "Sponsored Host Ratings") {
    requestPath = REACT_APP_ENDPOINT_HOST + "/host_ratings"
  }
  if (props.formName === "User Activity") {
    requestPath = REACT_APP_ENDPOINT_USER + "/user_activity"
  }

  caller = props.formName

  let refresh = props.refresh
  addButtonText = "Refresh"

  console.log("QueryContainer refresh=", refresh)


  const formData = GetData(requestPath, refresh, setGetDataComplete, timezoneOffset, userId )
  console.log ("QueryContainer formData =", formData)


  return (


    <Container fluid >

    <div className="d-flex align-items-center justify-content-center button-box">
    <div><br/> </div>
    <Button className="blue-button"
        onClick={() => {
          console.log("clicked set Refesh")
          if (props.refresh === false) {
                props.setRefresh(true)
            }
            else {
                props.setRefresh(false)
            }
        }}
    >{addButtonText}</Button>
    </div>

    {(!getDataComplete) &&
        <div >
          <Spinner animation="grow" size="sm" role="status" style={{color: "#00447c"}}>
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      }
      {(props.formName === "Client Billing Master") &&
        <ClientBillingMasterTable
            caller={caller}
            data={formData}
            setRefresh={props.setRefresh}
            refresh={refresh}
        />
    }
      {(props.formName === "Host Charges Review") &&
        <HostAuditTable
            caller={caller}
            data={formData}
            setRefresh={props.setRefresh}
            refresh={refresh}
        />
    }
    {(props.formName === "Sponsored Host Game Schedule") &&
        <GameScheduleTable
            caller={caller}
            data={formData}
            setRefresh={props.setRefresh}
            refresh={refresh}
        />
    }
    {(props.formName === "Sponsored Host Payables") &&
        <HostPayablesTable
            caller={caller}
            data={formData}
            setRefresh={props.setRefresh}
            refresh={refresh}
        />
    }
    {(props.formName === "Sponsored Host Ratings") &&
        <HostRatingTable
            caller={caller}
            data={formData}
            setRefresh={props.setRefresh}
            refresh={refresh}
        />
    }

    {(props.formName === "User Activity") &&
        <UserActivityTable
            caller={caller}
            data={formData}
            setRefresh={props.setRefresh}
            refresh={refresh}
        />
    }

    </Container>
  );
}

export default QueryContainer