import React from "react";
import { useForm } from "react-hook-form";
import { Container, Row, Col } from "react-bootstrap"
import "./Form.css"

const ContactInfo = (props) => {


    let preloadValues = {}
    // let formValues = []

    console.log("contactInfo formData=", props.formData)
    console.log("contactInfo props.keyValue=", props.keyValue)

    let formValues = props.formData.filter(obj => {
        return obj.game_schedule_id === props.keyValue
    })
    console.log("ContactInfo formValue= ", formValues)

    preloadValues = {
        game: formValues[0].game_name,
        scheduleInfo: formValues[0].schedule_date + " " + formValues[0].community_time,
        communityName: formValues[0].community_name,
        name: formValues[0].first_name + " " + formValues[0].last_name,
        email: formValues[0].email_id,
        phone: formValues[0].phone_number,
        hostName: formValues[0].host_name
        }

    const { register} = useForm({
        defaultValues: preloadValues
    });

    return (
    <div className="form-box">
    <Container fluid className="form-box">

        <form >
            <Row>
            <Col >
                <label className="label-format">Game:</label>
            </Col>
            <Col xs={9}>
                <input type="text" readOnly disabled="disabled" placeholder="Game Name" className="input-format"
                {...register("game")} />
            </Col>
            </Row>
            <br/>
            <Row>
            <Col >
                <label className="label-format">Scheduled At:</label>
            </Col>
            <Col xs={9}>
                <input type="text" readOnly disabled="disabled" placeholder="Scheduled at" className="input-format"
                {...register("scheduleInfo")} />
            </Col>
            </Row>
            <br/>
            <Row>
            <Col >
                <label className="label-format">Community:</label>
            </Col>
            <Col xs={9}>
                <input type="text" readOnly disabled="disabled" placeholder="Community" className="input-format"
                {...register("communityName")} />
            </Col>
            </Row>
            <br/>
            <Row>
            <Col >
                <label className="label-format">Game Contact:</label>
            </Col>
            <Col xs={9}>
                <input type="text" readOnly disabled="disabled" placeholder="Contact Name" className="input-format"
                {...register("name")} />
            </Col>
            </Row>
            <br/>
            <Row>
            <Col >
                <label className="label-format">Email Address:</label>
            </Col>
            <Col xs={9}>
                <input type="text" readOnly disabled="disabled" placeholder="Email Address" className="input-format"
                {...register("email")} />
            </Col>
            </Row>
            <br/>
            <Row>
            <Col >
                <label className="label-format">Phone Number:</label>
            </Col>
            <Col xs={9}>
                <input type="text" readOnly disabled="disabled" placeholder="Phone Number" className="input-format"
                {...register("phone")} />
            </Col>
            </Row>
            <br/>
            <Row>
            <Col >
                <label className="label-format">Scheduled Host:</label>
            </Col>
            <Col xs={9}>
                <input type="text" readOnly disabled="disabled" placeholder="Host Name" className="input-format"
                {...register("hostName")} />
            </Col>
            </Row>
            <br/>
        </form>
    </Container>
    </div>

    )

}

export default ContactInfo