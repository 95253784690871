import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Container, Row, Col, Button, Stack, Spinner} from "react-bootstrap"
import SelectAddressBar from "./SelectAddressBar";
import SelectContactBar from "./SelectContactBar";
import SelectStateProvinceBar from "./SelectStateProvinceBar";
import MessageModal from "./MessageModal"
import doValidations from "../utils/doValidations";
import ValidationQuery from "../utils/ValidationQuery"
import WriteData from "../utils/WriteData";
import ClientBillingProductsTable from "./ClientBillingProductsTable";
import "./Form.css"
import "./ClientBillingFormCRUD.css"

const ClientBillingFormCRUD = (props) => {

    const REACT_APP_ENDPOINT_ACCOUNTING=process.env.REACT_APP_ENDPOINT_ACCOUNTING;
    let apiPath = ''

    console.log("in ClientBillingFormCRUD")
    const setDataChange = props.setDataChange // destructure for useEffect
    const dataChange = props.dataChange
    // props.setDataChange(false)
    // console.log("******************ClientBillingFormCRUD dataChange= ", dataChange)
    // console.log("props.communityId=", props.communityId)
    // console.log("props.setRefresh=", props.setRefresh)
    // console.log("ClientBillingFormCRUD formData= ", props.formData)
    // console.log("ClientBillingFormCRUD sectionValue = ", props.HostAccountUserId)

    let displayCommunityName = ""

    // for use during database updates
    const [dbWorkComplete, setDbWorkComplete] = useState(true);
    const [postDelete, setPostDelete] = useState(false);  //to prevent a preLoadValues load in rerender after record deletion.
     // for use managing internal state when props.CRUD = "Add" to allow for page refreshes and managing
    // inserts versus updates
    const [beenInserted, setBeenInserted] = useState(false)

    // for use with Modal
    const [showInner, setShowInner] = useState(false)
    const handleClose = () => setShowInner(false)
    const [deleteWarning, setDeleteWarning] = useState(false)
    const [saveWarning, setSaveWarning] = useState(false)

    // for use during validation.  There are two types of validations:   lookups against preloaded values and database queries.
    // See doValidations and ValidationQuery(currently used for delete validations only).
    const [titleMessage, setTitleMessage] = useState(null)
    const [validationMessage, setValidationMessage] = useState(null)

    // Load data and key values necessary for subsequent processing
    //
    // Note:  There are repeated calls to *ValidationQuery*, originally written as a Validation
    // tool, which is now being reused to as a generic tool to access form data.  ValidationQuery
    // should at some point be renamed to something more meaningful.

    let maxUserId = 0
    let maxAddressId = 0
    let billingAddress = {}
    let billableProducts = {}

    let formValues = props.formData.filter(obj => {
        return obj.community_community_id === props.communityId
    })
    console.log("ClientBillingFormCurd formValues=", formValues)

    displayCommunityName = formValues[0].community_name

    maxUserId = ValidationQuery('/max_user_id', props.communityId, "accounting") + 40
    maxAddressId = ValidationQuery('/max_address_id', props.communityId, "accounting") + 40

    // get default community adresss if there's nothing set for client_billing_master
    if (formValues[0].address_id === null) {
        billingAddress = ValidationQuery('/billing_address', formValues[0].community_address_id, "accounting")
        console.log("address=", billingAddress)
    }

    // modifying from display value (on calling page) to internal value for database updates
    if (formValues[0].export_date === "N") {
        formValues[0].export_date = null
    }

    // load data for Licensed Products billable to Community table (at bottom)

    billableProducts = ValidationQuery('/client_billing_products', props.communityId, "accounting")
    console.log ("billableProducts=", billableProducts)

    let preloadValues = {}


    // for use with SelectContactBar, SelectAddressBar, SelectStateProvinceBar
    const [contactPicked, setContactPicked] = useState(false)
    const [selectedContactValues, setSelectedContactValues] = useState({})
    const [contactsData, setContactsData] = useState([{}])
    const [addressPicked, setAddressPicked] = useState(false)
    const [selectedAddressValues, setSelectedAddressValues] = useState({})
    const [addressData, setAddressData] = useState([{}])
    const [stateProvincePicked, setStateProvincePicked] = useState(false)
    const [selectedStateProvinceValues, setSelectedStateProvinceValues] = useState({})
    const [readComplete, setReadComplete] = useState (false)

    // determines if a billing address needs to be created
    function preproccessAddress (dirtyFields, data, maxAddressId) {

        let addressId = data.addressId
        let addressAction = "none"
        if (dirtyFields.country
            || dirtyFields.streetAddress
            || dirtyFields.city
            || dirtyFields.state
            || dirtyFields.zipPostal
            || dirtyFields.county) {
            if (data.addressStatus !== 100) {     // status = 100 means billing address - update record
                addressId = maxAddressId
                addressAction = "insert"
            } else if (data.addressStatus === 100 ) {  // form only updates billing addreses.  Not others
                addressAction = "update"
            }
        }
        return[addressId, addressAction]
    }

    // determines if a billing contact needs to be created or updated
    function preproccessContact (dirtyFields, data, maxUserId, contactPicked) {

        let userId = data.userId
        let contactAction = "none"
        if (dirtyFields.firstName
            || dirtyFields.lastName
            || dirtyFields.emailId) {
            if (data.userStatus !== 100) {    // status = 100 means billing contact - update record
                userId = maxUserId
                contactAction = "insert"
            } else if (data.userStatus === 100) {  // form only updates billing contacts.  Not others
                contactAction = "update"
            }
        }
        return[userId, contactAction]
    }

    // form submit processing
    const onSubmit = async data => {
        console.log(data)
        console.log ("in onSubmit formAction=", getValues("formAction"))
        setDbWorkComplete(false)

        // used for determining of address and contact data need to be inserted.  Note:  userStatus
        // and addressStatus are used to determine if we're dealing with billing records.  They're
        // written to user and address this way when billing records are created.  You'll see these
        // references below too.

        // set keys and database actions billing address and contact records
        const [addressId, addressAction] = preproccessAddress(dirtyFields, data, maxAddressId)
        const [contactId, contactAction] = preproccessContact(dirtyFields, data, maxUserId, contactPicked)
        data.userId = contactId
        data.addressId = addressId
        // folloiwng values are used on the server side
        data.addressAction = addressAction
        data.contactAction = contactAction

        // convert from array to string for write to database.  take the first value

        let passValidation = true
        // validation 1 - addresses and other
        passValidation = await doValidations(
            "ClientBillingFormCRUD",
            getValues("formAction"),
            dirtyFields,
            data,
            addressData,
            setValidationMessage,
            1,)
        // // validation 2 - contacts
        if (passValidation) {
            passValidation = await doValidations(
                "ClientBillingFormCRUD",
                getValues("formAction"),
                dirtyFields,
                data,
                contactsData,
                setValidationMessage,
                2,)
        }
        // passValidation = false

        if (Array.isArray( data.paymentMethod)) {
            data.paymentMethod = data.paymentMethod[0]
        }
        if (Array.isArray( data.deliveryMethod)) {
            data.deliveryMethod = data.deliveryMethod[0]
        }

        if (passValidation) {
            if (getValues("formAction") === "insert") {
                apiPath=REACT_APP_ENDPOINT_ACCOUNTING + "/client_billing_master_insert"
                WriteData(apiPath, data, "post", setDbWorkComplete)
                setBeenInserted(true) // set here to allow updates after insert when user stays on form
                setDbWorkComplete(true)
                // props.handleOuterClose()
            }
            else if (getValues("formAction") === "update") {
                apiPath=REACT_APP_ENDPOINT_ACCOUNTING + "/client_billing_master_update"
                WriteData(apiPath, data, "put", setDbWorkComplete, data.userId, props.refresh, props.setRefresh)
                setDbWorkComplete(true)
                props.handleOuterClose()
            }
            else if (getValues("formAction") === "delete") {
                apiPath=REACT_APP_ENDPOINT_ACCOUNTING + "/client_billing_master_delete"
                WriteData(apiPath, data, "delete", setDbWorkComplete, data.communityId, props.refresh, props.setRefresh)
                setPostDelete(true)
                setDbWorkComplete(true)
                props.handleOuterClose()
            }
            if (dbWorkComplete) {
                console.log ("back in ClientBillingFormCRUD dbWorkComplete")
                reset('',
                    {
                    keepValues: true
                })
                props.setDataChange(false)
                // this is a toggle to reset state for GetData to refresh after saves.
                // if (!props.refresh) {
                //     props.setRefresh(true)
                // }
                // else {
                //     props.setRefresh(false)
                // }
            }
        }
        else {
            // means a validation failed
            setTitleMessage("There is a data issue")
            setDbWorkComplete(true)
            setShowInner(true)
        }
    }

    // console.log("in ClientBillingFormCRUD dataChange= ", props.dataChange)


    // if (!postDelete) {
    if (!postDelete) {
        // console.log("ClientBillingFormCRUD formValue= ", formValues)
        preloadValues = {
            addressId: formValues[0].address_id,
            addressStatus: formValues[0].address_status,
            billingEmail: formValues[0].billing_email,
            city: formValues[0].city,
            communityId: formValues[0].community_community_id,
            communityName: formValues[0].community_name,
            country: formValues[0].country,
            county: formValues[0].county,
            dateJoined: formValues[0].date_joined,
            deliveryMethod: formValues[0].delivery_method,
            emailId: formValues[0].email_id,
            exportDate: formValues[0].export_date,
            firstName: formValues[0].first_name,
            lastName: formValues[0].last_name,
            masterStateProvinceId: formValues[0].master_state_province_id,
            officePhone: formValues[0].office_phone,
            organizationId: formValues[0].organization_id,
            paymentMethod: formValues[0].payment_method,
            phoneNumber: formValues[0].phone_number,
            productCount: formValues[0].product_count,
            state: formValues[0].state,
            streetAddress: formValues[0].street_address,
            userId: formValues[0].user_id,
            userStatus: formValues[0].user_status,
            webSite: formValues[0].web_site,
            zipPostal: formValues[0].zip_postal,
            formAction: "read"  // form buttons set this value to 'update', 'insert', 'delete'
        }
        console.log("in preloadValues preloadValuest=", preloadValues)
    }
    else {
        console.log("ClientBillingFormCRUD selectedContactValues =", selectedContactValues)
        preloadValues = {
            addressId: formValues[0].address_id,
            addressStatus: 0,
            billingEmail: null,
            city: null,
            communityId: formValues[0].community_community_id,
            communityName: null,
            country: null,
            county: null,
            dateJoined: formValues[0].date_joined,
            deliveryMethod: null,
            emailId: null,
            exportDate: formValues[0].export_date,
            firstName: null,
            lastName: null,
            masterStateProvinceId: 0,
            officePhone: null,
            organizationId: formValues[0].organization_id,
            paymentMethod: null,
            phoneNumber: null,
            productCount: 0,
            state: null,
            streetAddress: null,
            userId: 0,
            userStatus: 0,
            webSite: null,
            zipPostal: null,
            formAction: "read"  // form buttons set this value to 'update', 'insert', 'dele
        }
    }
    console.log ("preloadValues=", preloadValues)
    // for use in changing publish button colors to indicate status and chedking Previewing.

    const { register, handleSubmit, watch, getValues, setValue, reset,  resetField, formState: {dirtyFields}} = useForm({
        defaultValues: preloadValues,
        reValidateMode: 'onChange'
    });

    //update values based on data read on initial load
    useEffect(() => {
        if (formValues[0].address_id === null) {
            setValue("addressId", billingAddress.address_id)
            setValue("masterStateProvinceId", billingAddress.master_state_province_id)
            setValue("country", billingAddress.country)
            setValue("streetAddress", billingAddress.street_address)
            setValue("city", billingAddress.city)
            setValue("state", billingAddress.state)
            setValue("zipPostal", billingAddress.zip_postal)
            setValue("county", billingAddress.county)
            setValue("addressStatus", billingAddress.status)
            setDataChange(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [billingAddress]);

    // update values based on records picked from dropdown menus. Resets dirtyflags for user
    // enterable fields to start validations fresh when records are picked from dropdowns
    useEffect(() => {
        if (addressPicked !== false) {
            setValue('addressId', selectedAddressValues.addressId)
            resetField('country', {keepDirty: false})
            setValue('country', selectedAddressValues.country)
            resetField('streetAddress', {keepDirty: false})
            setValue('streetAddress', selectedAddressValues.streetAddress)
            resetField('city', {keepDirty: false})
            setValue('city', selectedAddressValues.city)
            resetField('zipPostal', {keepDirty: false})
            setValue('zipPostal', selectedAddressValues.zipPostal)
            resetField('state', {keepDirty: false})
            setValue('state', selectedAddressValues.state)
            resetField('county', {keepDirty: false})
            setValue('county', selectedAddressValues.county)
            setValue("addressStatus", selectedAddressValues.status)
            setDataChange(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [addressPicked])

      useEffect(() => {
        if (stateProvincePicked !== false) {
            setValue('country', selectedStateProvinceValues.country)
            setValue('state', selectedStateProvinceValues.state)
            setValue('masterStateProvinceId', selectedStateProvinceValues.masterStateProvinceId)
            setValue('streetAddress', null)
            setValue('city', null)
            setValue('zipPostal', null)
            setValue('county', null)
            setDataChange(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [stateProvincePicked])

    useEffect(() => {
        if (contactPicked !== false) {
            setValue('userId', selectedContactValues.userId)
            resetField('firstName', {keepDirty: false})
            setValue('firstName', selectedContactValues.firstName)
            resetField('lastName', {keepDirty: false})
            setValue('lastName', selectedContactValues.lastName)
            resetField('emailId', {keepDirty: false})
            setValue('emailId', selectedContactValues.emailId)
            resetField('phoneNumber', {keepDirty: false})
            setValue('phoneNumber', selectedContactValues.phoneNumber)
            setValue("roleId", selectedContactValues.roleId)
            setValue("userStatus", selectedContactValues.userStatus)
            setDataChange(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [contactPicked])

    // watch tracks user input
    watch ()

    useEffect(() => {
        const subscription = watch(() => setDataChange(true))
        console.log("-----------data change useEffect=", dataChange)
        return () => subscription.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch]);



    return (

    <div className="form-box">
    <div className="d-flex justify-content-center big-blue-text">
        {displayCommunityName}
    </div>
    <Container fluid className="form-box">
        <MessageModal
            show={showInner}
            handleClose={handleClose}
            titleMessage={titleMessage}
            validationMessage={validationMessage}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
            <Row >
                <Col md="auto">
                <Stack direction="horizontal" gap={0} >
                     {(readComplete === false) &&
                        <Spinner animation="grow" size= "sm" role="status" style={{color: "#00447c"}}>
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    }
                    <SelectAddressBar
                        refresh={props.refresh}
                        setAddressPicked={setAddressPicked}
                        setSelectedAddressValues={setSelectedAddressValues}
                        setReadComplete={setReadComplete}
                        keyValue={formValues[0].organization_id}
                        addressData={addressData}
                        setAddressData={setAddressData}  // returns values from query done in SelectAddressBar for later validation
                    />
                    <SelectStateProvinceBar
                        refresh={props.refresh}
                        setStateProvincePicked={setStateProvincePicked}
                        setSelectedStateProvinceValues={setSelectedStateProvinceValues}
                        setReadComplete={setReadComplete}
                        // keyValue={formValues[0].organization_id}
                    />
                </Stack>
                </Col>
            </Row>

                <div className = "section-divider">
                     <h4>Billing Address, Preferences and Website Information</h4>
                </div>
            <br/><br/>

            <Row display="table" className="align-items-center justify-content-center">
            <Col md="1">
                <label className="billing-master-label-format">Country*</label>
            </Col>

            <Col md="1">
                <input type="text"
                readOnly
                disabled="disabled"
                placeholder="Co." className="billing-master-input-format"
                {...register("country", {required: true})} />
            </Col>

            <Col md="1" >
                <label className="billing-master-label-format">Street*</label>
            </Col>
            <Col md="4">
                <input type="text"

                placeholder="Street Address" className="billing-master-input-format"
                {...register("streetAddress", {required: true, maxLength: 200, })} />
            </Col>
            <Col md="1" >
                <label className="billing-master-label-format">City*</label>
            </Col>
            <Col md="4">
                <input type="text"
                placeholder="City" className="billing-master-input-format"
                {...register("city", {required: true,  maxLength: 50, })} />
            </Col>
            </Row>

            <Row display="table" className="align-items-center justify-content-center">
            <Col md="1">
                <label className="billing-master-label-format">State*</label>
            </Col>
            <Col md="1">
                <input type="text"
                readOnly
                disabled="disabled"
                placeholder="State" className="billing-master-input-format"
                {...register("state", {required: true})} />
            </Col>

            <Col md="1" >
                <label className="billing-master-label-format">Zip*</label>
            </Col>
            <Col md="4">
                <input type="text"
                placeholder="Zip / Postal Code" className="billing-master-input-format"
                {...register("zipPostal", {required: true, maxLength: 200, })} />
            </Col>
            <Col md="1" >
                <label className="billing-master-label-format">County*</label>
            </Col>
            <Col md="4">
                <input type="text"
                placeholder="County" className="billing-master-input-format"
                {...register("county", {required: true, maxLength: 200, })} />
            </Col>

            </Row>

            <Row display="table" className="align-items-center justify-content-start">
            <Col md="2" >
                <label className="billing-master-label-format">Office Phone*</label>
            </Col>
            <Col md="2">
                <input type="tel"
                placeholder="Phone Number"
                className="billing-master-input-format"
                {...register("officePhone", {required: true, maxLength: 12, })} />
            </Col>
            <Col md="2" >
                <label className="billing-master-label-format">Billing Email*</label>
            </Col>
            <Col md="4">
                <input type="email"
                placeholder="Billing Email Address" className="billing-master-input-format"
                {...register("billingEmail", {required: true,  maxLength: 50, pattern: /^\S+@\S+$/i})} />
            </Col>


            </Row>
            <Row display="table" className="align-items-center justify-content-start">
            <Col md="2" >
                <label className="billing-master-label-format">Payment Method*</label>
            </Col>
            <Col md="2">
                <select multiple placeholder="Payment" size="4" className="billing-master-input-format"
                {...register("paymentMethod", {required: true})}>
                 <option value="ACH">ACH</option>
                 <option value="Check">Check</option>
                 <option value="Credit Card">Credit Card</option>
                 <option value="Annual Prepaid">Annual Prepaid</option>
                </select>
            </Col>
            <Col md="2" >
                <label className="billing-master-label-format">Delivery Method*</label>
            </Col>
            <Col md="2">
                <select multiple placeholder="Payment" size="2" className="billing-master-input-format"
                {...register("deliveryMethod", {required: true})}>
                 <option value="Email">Email</option>
                 <option value="Mail">Mail</option>
                </select>
            </Col>
            </Row>
            <Row className="align-items-center justify-content-start">
            <Col >
                <label className="billing-master-label-format">Community Website*</label>
            </Col>
            <Col xs={9}>
                <textarea type="text"  rows="1" placeholder="Paste website link here" className="billing-master-input-format"
                {...register("webSite", { required: true, max: 500, maxLength: 2000})} />
            </Col>
            </Row>
            {/* <br/> */}
            <br/>

            <SelectContactBar
                refresh={props.refresh}
                setContactPicked={setContactPicked}
                setSelectedContactValues={setSelectedContactValues}
                setReadComplete={setReadComplete}
                keyValue={formValues[0].organization_id}
                contactsData={contactsData}
                setContactsData={setContactsData}
            />

            {/* <br/><br/> */}
                <div className = "section-divider">
                     <h4>Billing Contact Information</h4>
                </div>
            <br/><br/>
            <Row display="table" className="align-items-center justify-content-center">
            <Col md="1">
                <label className="billing-master-label-format">First Name</label>
            </Col>
            <Col md="2">
                <input type="text"
                placeholder="First Name" className="billing-master-input-format"
                {...register("firstName", {required: true})} />
            </Col>

            <Col md="1" >
                <label className="billing-master-label-format">Last Name</label>
            </Col>
            <Col md="2">
                <input type="text"
                placeholder="Last Name" className="billing-master-input-format"
                {...register("lastName", {required: true})} />
            </Col>
            <Col md="1" >
                <label className="billing-master-label-format">Email</label>
            </Col>
            <Col md="2">
                <input type="email"
                placeholder="Email Address" className="billing-master-input-format"
                {...register("emailId", {required: true,  maxLength: 50, pattern: /^\S+@\S+$/i})} />
            </Col>
            <Col md="1" >
                <label className="billing-master-label-format">Phone*</label>
            </Col>
            <Col md="2">
                <input type="tel"
                placeholder="Phone Number"
                className="billing-master-input-format"
                {...register("phoneNumber", {required: true, maxLength: 12, })} />
            </Col>
            </Row>
            <br/>

            <br/>

            {/* conditionally render CRUD buttons based on form mode. */}

            <Row className="justify-content-center">

                    <>
                    <Stack direction="horizontal" gap={3} className="d-flex justify-content-center">
                    {(props.CRUD === "ReadUpdateDelete") &&
                    <>
                    <Button  className="blue-button"
                        onClick={() => {
                            console.log ("Save= ", props.CRUD)
                            setValue("formAction", "update")
                            handleSubmit(onSubmit)()

                    }}
                    >Save</Button>
                    <Button className="blue-button"
                        onClick={() => {
                            if (deleteWarning === false) {
                                setTitleMessage("You are about to delete a Client Billing Master Record")
                                setValidationMessage("This may impact UE's revenue. Please be certain you want to do this.")
                                setShowInner(true)
                                setDeleteWarning(true)
                            }
                            else {
                                setValue("formAction", "delete")
                                handleSubmit(onSubmit)()
                            }
                    }}
                    >Delete</Button>
                    </>
                    }
                    {(props.CRUD === "Add") &&
                    <Button type="submit" className="blue-button"
                            onClick={() => {
                                console.log ("Save= ", props.CRUD)
                                console.log ("beenInserted=", beenInserted)
                                if (beenInserted === false) {
                                    setValue("formAction", "insert")
                                }
                                else {
                                    setValue("formAction", "update")
                                }
                         }}
                    >Save</Button>
                    }
                    </Stack>
                    </>
                    <p>* Required field</p>
            </Row>
            <br/>
                <div className = "section-divider">
                     <h4>Licensed Products Billed to this Community</h4>
                </div>
            <br/><br/>
            <ClientBillingProductsTable
                caller={props.caller}
                data={billableProducts}
                setRefresh={props.setRefresh}
                refresh={props.refresh}
            />
            {(!dbWorkComplete) &&
                    <div>
                    <Spinner animation="border" role="status" style={{color: "#00447c"}}>
                    <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    </div>
            }
        </form>
    </Container>
    </div>

    )

}

export default ClientBillingFormCRUD